/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";
import { addWidthToElement, addHeightToElement, blockOverflow } from "interface/util/StylesUtil";

export default class MessageSender {
    initialize(config, messagingService) {
        this._config = config;
        this._messagingService = messagingService;
        this._createIFrame();
        const data = {
            config: this._config,
        };
        this._messagingService.sendMessage(IFrameMessageTypes.INIT, data);
    }

    isInstanceStillAlive = () => this._iframeElement && !!this._iframeElement.contentWindow;

    _createIFrame() {
        const doc = window.document;
        const iFrame = doc.createElement("iframe");
        const divElement = doc.getElementById(this._config.divId);
        divElement.innerHTML = "";
        if (!divElement.internallyCreated) {
            if (!divElement.clientWidth) {
                addWidthToElement(divElement);
            }
            if (!divElement.clientHeight) {
                addHeightToElement(divElement);
            }
            blockOverflow(divElement);
        }
        iFrame.setAttribute("src", this._config.iframeSource);
        iFrame.setAttribute("id", this._config.iframeId);
        iFrame.setAttribute("allowfullscreen", "allowfullscreen");
        iFrame.setAttribute("title", "PDF Embed API");
        iFrame.style.width = "100%";
        iFrame.style.height = "100%";
        iFrame.style.border = "none";
        iFrame.style.display = "block";
        divElement.appendChild(iFrame);
        this._iframeElement = doc.getElementById(this._config.iframeId);
        this._setMessageReceiver();
    }

    _setMessageReceiver() {
        const receiveMessage = type => {
            if (type === IFrameMessageTypes.READY) {
                const anchor = document.createElement("a");
                anchor.href = this._config.ownSrc;
                const target = `https://${(anchor.host || anchor.href.match(/\/\/([^/]+)/)[1])}`;
                const sendMessage = messageData => {
                    if (this.isInstanceStillAlive()) {
                        this._iframeElement.contentWindow.postMessage(messageData, target);
                    }
                };
                this._messagingService.setMessageSender(sendMessage);
            }
        };
        this._messagingService.setMessageReceiver(receiveMessage, window, this.isInstanceStillAlive);
    }
}
