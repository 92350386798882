/*************************************************************************
 * ADOBE SYSTEMS INCORPORATED
 *  Copyright 2018 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying it.
 * If you have received this file from a source other than Adobe, then your
 * use, modification, or distribution of it requires the prior written
 * permission of Adobe.
 **************************************************************************/
/**
 * @file
 */

import { http } from 'adobe-dcapi-web';
import template from 'url-template';
import { getEnvVar } from '../core/EnvUtil';

import { getSingletonFunction } from '../core/ProviderUtil';
import DcapiAPI from './DcapiAPI';
import { auth2 } from './Auth2API';

export const CONTENT_TYPE_USER = 'user_v1.json';
export const CONTENT_TYPE_LIMITS_ESIGN = 'user_limits_esign_v1.json';
export const CONTENT_TYPE_USER_PREFS = 'user_prefs_v1.json';

export const OPERATION_USER_GET = 'users.get_user';
export const OPERATION_LIMITS_ESIGN = 'users.get_limits_esign';
export const OPERATION_PREFS_GET = 'users.get_prefs';
export const OPERATION_PREFS_PUT = 'users.put_prefs';

export const USER_TAG = {
  ageGroupUnder13: 'agegroup_under13',
  ageGroup13To15: 'agegroup_13_15',
  ageGroup16To17: 'agegroup_16_17',
  ageGroup18Plus: 'agegroup_18plus',
  ageGroupUnknown: 'agegroup_unknown',
  eduK12: 'edu_k12',
  eduStudent: 'edu_student',
};

export const BLOCK_LISTED_COUNTRY_CODES = ['CU', 'IR', 'KP', 'SY'];

let userPromise;
let user = {};
let dcapi;
let dcapiPromise;
let esignPromise;
let sendPromise;
let baseUrisPromise;
let accessTokenPromise;
let genAIProvisionStatusPromise;
let userGeoPromise;
let isGenAIEligiblePromise;
let genAIUriPromise;
let genAIUriExpiry;

export const isPrimitiveValue = value => Object(value) !== value;

const getDcapi = () => {
  if (dcapiPromise) {
    return dcapiPromise;
  }
  dcapiPromise = DcapiAPI.getInstance()
    .then(dcapiInstance => dcapiInstance.getDcapi())
    .catch(e => {
      dcapiPromise = undefined;
      throw e;
    });
  return dcapiPromise;
};

const setUser = content => {
  if (!content) {
    // if no content reset user promise
    userPromise = undefined;
    return content;
  }
  user = content;
  // remove prefs content that no longer fits our schema
  const dcwebPrefs = user['prefs/dcweb'];
  if (dcwebPrefs && dcwebPrefs.dcweb) {
    const fte = dcwebPrefs.dcweb.fte;
    if (fte) {
      delete fte.a11y;
      delete fte.launch_count;
    }
    const tour = dcwebPrefs.dcweb.coach_mark_tour;
    if (tour) {
      Object.keys(tour).forEach(t => {
        delete tour[t].lastDateSeen;
        delete tour[t].timesSeen;
      });
    }
  }
  return user;
};

/**
 * @method
 * @returns {object} - success - user aggregated info
 *                     failure - Error
 * @private
 */
const getUser = async () => {
  if (!userPromise) {
    const options = {
      accept: CONTENT_TYPE_USER,
    };

    const fields = [
      'identity',
      'limits/acrobat',
      'limits/conversions',
      'limits/pdf_services',
      'limits/esign',
      'limits/verbs',
      'prefs/dcweb',
      'prefs/recent_assets',
      'prefs/recent_assets_timestamp',
      'prefs/common',
      'subscriptions',
    ];

    const requestAccessAppIds = getEnvVar('request_access_app_ids');
    if (requestAccessAppIds) {
      const requestAccessField = encodeURIComponent(`request_provisioning?appIds=${requestAccessAppIds}`);
      fields.push(requestAccessField);
    }

    options.uri_parameters = {
      fields: fields.join(','),
    };

    userPromise = getDcapi().then(api => {
      if (!dcapi) {
        dcapi = api;
      }
    }).then(() => dcapi.call(OPERATION_USER_GET, options))
      .then(({ content }) => {
        if (content?.subscriptions?.subscriptions) {
          content.subscriptions.subscriptions = content.subscriptions.subscriptions.filter(
            sub => sub.name !== 'GenAIServices');
        }
        return setUser(content);
      })
      .catch(e => {
        userPromise = undefined;
        throw e;
      });
  }
  return userPromise;
};

/**
 * @method
 * @returns {object} - success - user aggregated info
 *                     failure - Error
 * @private
 */
const getLimitsEsign = async () => {
  if (!esignPromise) {
    esignPromise = getDcapi().then(api => {
      if (!dcapi) {
        dcapi = api;
      }
    }).then(() => dcapi.call(OPERATION_LIMITS_ESIGN, {
      accept: CONTENT_TYPE_LIMITS_ESIGN,
    }))
      .then(({ content }) => content)
      .catch(e => {
        esignPromise = undefined;
        throw e;
      });
  }
  return esignPromise;
};

/**
 * @method
 * @returns {object} - success - the bearer access token
 *                     failure - Error
 * @private
 */
const getAccessToken = () => {
  if (!accessTokenPromise) {
    accessTokenPromise = auth2.getSessionToken()
      .then(({ content }) => {
        if (!content.access_token) {
          return;
        }
        return `Bearer ${content.access_token}`;
      })
      .catch(e => {
        accessTokenPromise = undefined;
        throw e;
      });
  }
  return accessTokenPromise;
};

/**
 * @method
 * @returns {object} - success - the send and track base uris
 *                     failure - Error
 * @private
 */
const getSendAndTrackUris = () => {
  if (!baseUrisPromise) {
    const fileTierBaseUri = getEnvVar('files_uri');
    if (!fileTierBaseUri) {
      return Promise.reject(new ReferenceError('missing files_uri'));
    }
    const headers = {
      Accept: 'application/vnd.adobe.dex+json;version=1',
      'x-api-client-id': 'api_browser',
      'x-api-app-info': 'dc-web-app',
    };

    baseUrisPromise = getAccessToken()
      .then(token => {
        if (token) {
          headers.Authorization = token;
        }
      })
      .then(() => http.get(`${fileTierBaseUri}/api/base_uris`, { headers }))
      .then(({ content }) => JSON.parse(content))
      .catch(e => {
        baseUrisPromise = undefined;
        throw e;
      });
  }
  return baseUrisPromise;
};

/**
 * @method
 * @returns {object} - success - limits info for track and send
 *                     failure - Error
 * @private
 */
const getLimitsSend = () => {
  if (!sendPromise) {
    sendPromise = Promise.all([getSendAndTrackUris(), getAccessToken()])
      .then(([baseUris, accessToken]) => {
        const headers = {
          Authorization: accessToken,
          Accept: 'application/vnd.adobe.dex+json;version=1',
          'x-api-client-id': 'api_browser',
          'x-api-app-info': 'dc-web-app',
        };
        return http.get(`${baseUris.send_api}users/me/limits`, { headers });
      }).then(({ content }) => JSON.parse(content))
      .catch(e => {
        sendPromise = undefined;
        throw e;
      });
  }
  return sendPromise;
};

/**
 * @classdesc
 * Service provider with access to DCAPI
 * Wraps key user info DCAPI calls
 * @class
 */
class UserAPI {
  /**
  * Constructor for UserAPI
  * @constructor
  * @param {object} [config={}] - configuration object
  * @param {Promise} [config.userDataPromise] - Promise that resolves to user data
  * @returns {UserAPI} - instance of UserAPI
  * @public
  */
  constructor({ userDataPromise } = {}) {
    const self = this;
    // eslint-disable-next-line no-undef
    window.addEventListener('dc.imslib.user_profile_updated', () => {
      self.clear();
    });

    if (userDataPromise) {
      userPromise = userDataPromise;
    } else {
      // eslint-disable-next-line no-undef
      const appDataPromise = window.adobe_dc_sdk?.appDataReady;
      if (appDataPromise) {
        userPromise = appDataPromise.promise.then(appData => {
          if (appData?.data?.users?.identity) {
            return appData.data.users;
          }
          // user data not available. Reset userPromise
          userPromise = undefined;
          return undefined;
        }).catch(() => {
          userPromise = undefined;
        });
      }
    }
    if (userPromise) {
      userPromise
        .then(content => setUser(content))
        .catch(() => {
          userPromise = undefined;
        });
    }
  }

  /** Instance of dcapi client to use */
  dcapi = undefined;

  /**
   * @description
   * Standard provider ready() method to allow lazy instantiation of API.
   * @method
   * @returns {Promise} - promise that resolves when user provider has been instantiated
   */
  ready() {
    return Promise.resolve(this);
  }

  /**
   * @description
   * Discard all cached user information in this provider
   *
   * @returns {object} returns a reference to this provider
   * @method
   */
  clear() {
    userPromise = undefined;
    esignPromise = undefined;
    sendPromise = undefined;
    baseUrisPromise = undefined;
    accessTokenPromise = undefined;
    genAIProvisionStatusPromise = undefined;
    userGeoPromise = undefined;
    isGenAIEligiblePromise = undefined;
    this.profilePictureMap = undefined;
    genAIUriPromise = undefined;
    genAIUriExpiry = undefined;
    return this;
  }

  /**
   * @method
   * @returns {object} - success - user aggregated info
   *                     failure - Error
   * @public
   */
  getUser() {
    return getUser().then(userObj => {
      userObj = { ...userObj };
      delete userObj['prefs/dcweb'];
      return userObj;
    });
  }

  /**
   * @method
   * @returns {object} - success - esign limits
   *                     failure - Error
   * @public
   */
  getLimitsEsign() {
    return getLimitsEsign().then(limits => limits.esign_access);
  }

  /**
   * @method
   * @returns {object} - success - send and track limits
   *                     failure - Error
   * @public
   */
  getLimitsSend() {
    return getLimitsSend();
  }

  /**
   * @method
   * @returns {object} - success
   *                        true - if original sharing is enable
   *                        false - otherwise
   *                     failure - if error occurs while fetching limits
   * @public
   */
  isOriginalSharingEnabled() {
    return getLimitsSend()
      .then(limits => limits.original_sharing_enabled)
      .catch(() => false);
  }

  /**
   * @method
   *
   * Get base uris for various services
   *
   * @returns {Promise<object>} returns a promise that resolves with
   * base uris of various services
   *
   * @public
   */
  getFilesTierBaseUris() {
    return getSendAndTrackUris();
  }

  /**
   * @method
   * @returns {object} -  success - user identity
   *         failure - Error
   * @public
   */
  getIdentity() {
    return getUser().then(({ identity }) => identity);
  }

  /**
   * @method
   * @returns {object} -  success - user identity
   *         failure - Error
   * @public
   */
  getSubscriptions() {
    return getUser().then(({ subscriptions }) => subscriptions);
  }

  /**
   * @method
   * @returns {boolean} -  if user is able to edit pdf
   * @public
   */
  canEdit() {
    return getUser().then(res => res['limits/conversions'].edit_pdf_ops.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canExport() {
    return getUser().then(res => res['limits/conversions'].export_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canCreate() {
    return getUser().then(res => res['limits/conversions'].create_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canCombine() {
    return getUser().then(res => res['limits/conversions'].combine_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canOrganize() {
    return getUser().then(res => res['limits/conversions'].organize_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canSplit() {
    return getUser().then(res => res['limits/conversions'].split_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canCompress() {
    return getUser().then(res => res['limits/conversions'].optimize_pdf_ops.remaining !== 0);
  }

  /**
   * @description
   * Gets the user's conversions limits (file size limit, conversions remaining, etc)
   * Schema: https://dc-api-dev.adobe.io/schemas/user_limits_conversions_v1.json
   * @method
   * @returns {object} -  User's conversion limits
   * @public
   */
  getLimitsConversions() {
    return getUser().then(res => res['limits/conversions']);
  }

  /**
   * @method
   * @returns {object} -  User's acrobat limits, e.g. {acrobat_std, acrobat_pro}
   * @public
   */
  getLimitsAcrobat() {
    return getUser().then(res => res['limits/acrobat']);
  }

  /**
   * @description
   * Gets the limits for each verb
   * Schema: https://dc-api-dev.adobe.io/schemas/user_limits_conversions_v1.json
   * @method
   * @returns {object} -  User's conversion limits
   * @public
   */
  getLimitsVerbs() {
    return getUser().then(res => res['limits/verbs']);
  }

  /**
   * @method
   * @returns {boolean} -  if user is able to crop pdf
   * @public
   */
  canCrop() {
    return getUser().then(res => res['limits/verbs']['crop-pages'].limits.remaining !== 0);
  }

  /**
   * @description
   * Gets the values associated with the specified user preference category. If the category
   * is a slash-delimited path (eg. dcweb/fte), this will return only the specified subset. If
   * the subset cannot be found for a valid category, this returns <code>undefined</code>.
   * @method
   * @param {string} category - name of preference category to fetch. This value can also be
   *                            a path (delimited by "/") to a preference subtree or specific
   *                            value.
   *
   * @returns {object} - success - The specified preference(s)
   *         failure - Error
   * @public
   */
  getPreferences(category) {
    const [mainCategory, ...path] = category.split('/');

    return getUser().then(userObj => {
      // use the cached user, in case setPreferences has
      // been called.
      const userPrefs = userObj[`prefs/${mainCategory}`]
        ? userObj[`prefs/${mainCategory}`][mainCategory] : undefined;
      if (!path || path.length === 0) {
        return userPrefs;
      }
      // This will iterate over the path segments to traverse the preference data/tree.
      return path.reduce((prefs, name) => {
        if (prefs && name in prefs) {
          return prefs[name];
        }
        return undefined;
      }, userPrefs);
    });
  }

  /**
   * @description
   * Updates the values assocated with the specified user preference category.
   * @method
   * @param {string} category - name of preference category to set.
   *
   * @param {(object|string)} preferences - Object/String with all or a preference for given category. This
   *                               will completely replace the current category's
   *                               preferences.
   *
   * @returns {Promise} - If successful this resolves without a value. If there is
   *                     a failure, the promise will be rejected with an error object.
   * @public
   */
  setPreferences(category, preferences) {
    const [mainCategory, ...remainingCategories] = category.split('/');

    return this.getPreferences(mainCategory)
      .then((fullPreferences = {}) => {
        const oldPreferences = JSON.parse(JSON.stringify(fullPreferences));
        if (remainingCategories && remainingCategories.length >= 1) {
          let lastLevel = null;
          let lastKey = null;
          remainingCategories.reduce((userPreferences, currentPref) => {
            lastLevel = userPreferences;
            lastKey = currentPref;
            return userPreferences[currentPref]
              ? userPreferences[currentPref] : userPreferences[currentPref] = {};
          }, fullPreferences);
          if (isPrimitiveValue(preferences)) {
            lastLevel[lastKey] = preferences;
          } else if (Array.isArray(preferences)) {
            lastLevel[lastKey] = [...preferences];
          } else {
            lastLevel[lastKey] = { ...lastLevel[lastKey], ...preferences };
          }
        } else {
          // there is no path in the category, modify the main category
          // We shouldn't be allowing primitive values for top level pref (dcweb)
          // This block will be removed in the future
          // eslint-disable-next-line
          if (isPrimitiveValue(preferences)) {
            fullPreferences = preferences;
          } else if (Array.isArray(preferences)) {
            fullPreferences = [...preferences];
          } else {
            fullPreferences = { ...fullPreferences, ...preferences };
          }
        }

        return getUser().then(async userObj => {
          if (!userObj[`prefs/${mainCategory}`]) {
            userObj[`prefs/${mainCategory}`] = {};
          }
          user[`prefs/${mainCategory}`][mainCategory] = fullPreferences;
          if (!dcapi) {
            dcapi = await getDcapi();
          }
          return dcapi.call(OPERATION_PREFS_PUT, {
            content_type: CONTENT_TYPE_USER_PREFS,
            uri_parameters: {
              category: mainCategory,
            },
            content: {
              [mainCategory]: fullPreferences,
            },
          }).then(res => {
            user[`prefs/${mainCategory}`][mainCategory] = fullPreferences;
            return res;
          }).catch(e => {
            // revert changes in case of failure
            user[`prefs/${mainCategory}`][mainCategory] = oldPreferences;
            throw e;
          });
        });
      });
  }

  /**
   * @method
   * @returns {boolean} - true if user has only Free level subscriptions
   *         failure - Error
   * @public
   */
  isFreeUser() {
    return this.getSubscriptions().then(subscriptions => subscriptions.subscriptions.every(sub => sub.level === 'Free'));
  }

  /**
   * @method
   * Determines the user's primary storage type
   * @returns Promise<{('ACP' | 'SC')}> - The user storage type which can be either ACP or SC
   * @public
   */
  async getStorageType() {
    if (!dcapi) {
      dcapi = await getDcapi();
    }
    return dcapi.templates.search_uri_primary === '{+search_uri_v2}' ? 'ACP' : 'SC';
  }

  /**
   * Reset DcAPi. Used only for test
   * @private
   */
  __resetDcApi() {
    dcapi = undefined;
    dcapiPromise = undefined;
  }

  getGenAIProvisionUri = async (operationName, useV2) => {
    if (!dcapi) {
      dcapi = await getDcapi();
    }
    const resourceUrl = dcapi.templates.dc_index_uri;
    const appInfo = dcapi.config['x-api-app-info'];
    const clientId = dcapi.config['x-api-client-id'];
    if (!resourceUrl) {
      throw new Error('dc_index_uri not found');
    }
    let servicesObject;
    const currentTs = (new Date()).getTime() / 1000;
    if (genAIUriPromise && currentTs < genAIUriExpiry) {
      // get servcies from cached promise
      servicesObject = await genAIUriPromise;
    } else {
      // if expired, make get call again
      const authToken = auth2.getAccessTokenInfo().access_token;
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        'x-api-app-info': appInfo,
        'x-api-client-id': clientId,
      };
      const response = await http.get(resourceUrl, { headers });
      if (response.status === 200) {
        const { expiry, services } = JSON.parse(response.content);
        genAIUriPromise = new Promise(resolve => resolve(services));
        genAIUriExpiry = expiry;
        servicesObject = services;
      } else {
        throw new Error(`Unable to fetch discoverable genai uri. Failed with status code:${response.status}`);
      }
    }

    if (servicesObject) {
      const service = useV2 ? servicesObject.gen_ai_provisioning_v2 : servicesObject.gen_ai_provisioning;
      if (service) {
        const operation = service.resources?.operations[operationName];
        if (operation && operation.uri && operation.resource_parameter) {
          const {
            uri, resource_parameter: resourceParameter, accept,
          } = operation;
          const baseUri = resourceParameter.default;
          const urlTemplate = template.parse(uri);
          const url = urlTemplate.expand({ base_uri: baseUri });
          const additionalHeaders = {};
          if (useV2) {
            additionalHeaders['x-api-client-id'] = clientId;
            if (accept) additionalHeaders.Accept = accept;
          }
          return {
            url,
            additionalHeaders,
          };
        }
      }
    }
    throw new Error(`not able to fetch resource url for ${operationName}`);
  }

  /**
   * @description
   * Calls the Provision Status API to get the GenAI provision status for the user.
   * @method
   * @param {bool} useCache - If true, the cached value will be returned if available.
   * @param {bool} useV2 - If true, v2 of provisioning service will be used.
   * @returns {Promise} - If successful this resolves values
   *  {
   *      status: NOT_ELIGIBLE | CAN_SHOW_SIGNUP | CAN_SHOW_TRY_NOW | WAITLISTED | PROVISIONED
   *     profile: {account_type: "T1", customer_tyoe: "individual"}
   *     can_contact_admin: true | false
   * }
   * If there is a failure, the promise will be rejected with an error object.
   * @public
   */
  getUserGenAIProvisionStatus(useCache = true, useV2 = false) {
    if (useCache && genAIProvisionStatusPromise) return genAIProvisionStatusPromise;
    if (!auth2.isSignedIn) {
      const errorObj = new Error('User is not Signed-In');
      genAIProvisionStatusPromise = Promise.reject(errorObj);
      return genAIProvisionStatusPromise;
    }

    const clientId = auth2.getClientID();
    let headers = {
      Accept: 'application/json',
      'x-api-key': clientId,
    };
    genAIProvisionStatusPromise = this.getGenAIProvisionUri('status', useV2).then(({ url, additionalHeaders }) => getAccessToken().then(token => {
      if (token) {
        headers.Authorization = token;
        headers = { ...headers, ...additionalHeaders };
      }
    })
      .then(() => {
        const genAIStatusUri = `${url}?group_name=gen_ai_initial`;
        return http.get(genAIStatusUri, { headers });
      })
      .then(response => {
        if (response.status === 200) {
          return JSON.parse(response.content);
        }
        const errorObj = new Error(`Provison Status Failed: {"status": ${response.status},"statusText": "${response.statusText}"}`);
        throw errorObj;
      }))
      .catch(err => {
        genAIProvisionStatusPromise = undefined;
        throw err;
      });
    return genAIProvisionStatusPromise;
  }

  /**
   * @description
   * Calls the Provision API to Provision Server Resources for GenAI features.
   * @method
   * @param {string} - provisioningType - INSTANT_PROVISIONING | WAITLIST
   * @param {bool} - useV2 - If true, v2 of provisioning service will be used.
   * @returns {Promise} - If successful this resolves values
   *  If there is a failure, the promise will be rejected with an error object.
   * @public
   */
  provisionUserForGenAI(provisioningType, useV2 = false) {
    if (!auth2.isSignedIn) {
      const errorObj = new Error('User is not Signed-In');
      return Promise.reject(errorObj);
    }
    const clientId = auth2.getClientID();
    let headers = {
      Accept: 'application/json',
      'x-api-key': clientId,
      'Content-Type': 'application/json',
    };

    const body = JSON.stringify({
      provisioning_type: provisioningType,
      group_name: 'gen_ai_initial',
    });

    return this.getGenAIProvisionUri('provision', useV2)
      .then(({ url, additionalHeaders }) => getAccessToken()
        .then(token => {
          if (token) {
            headers.Authorization = token;
            headers = { ...headers, ...additionalHeaders };
          }
        })
        .then(() => http.post(url, { headers }, body))
        .then(response => {
          if (response.status === 200) {
            return JSON.parse(response.content);
          }
          const errorObj = new Error(`Provisioning Failed: {"status": ${response.status},"statusText": "${response.statusText}"}`);
          throw errorObj;
        }));
  }

  /**
   * @description
   * Get geo location of the user
   * @method
   * @returns {Promise} - If successful this resolves with the geo data.
   * eg: { country: 'IN', state: 'UP', 'Accept-Language': 'en-US,en;q=0.9' }
   * If there is a failure, the promise will be rejected with an error object.
   * @public
   */
  getUserGeoLocation() {
    if (userGeoPromise) return userGeoPromise;
    const geoUri = 'https://geo-dc.adobe.com/json/';
    const headers = {
      Accept: 'application/json',
    };
    userGeoPromise = http.get(geoUri, { headers })
      .then(response => {
        if (response.status === 200) {
          return JSON.parse(response.content);
        }
        const errorObj = new Error(`GeoLocation Failed: {"status": ${response.status},"statusText": "${response.statusText}"}`);
        throw errorObj;
      })
      .catch(err => {
        userGeoPromise = undefined;
        throw err;
      });
    return userGeoPromise;
  }

  _isNonType1UserGenAIEligible(useV2) {
    return this.getUserGenAIProvisionStatus(true, useV2).then(resp => {
      if (resp.status !== 'NOT_ELIGIBLE') {
        return true;
      }
      if (resp.can_contact_admin) {
        return true;
      }
      throw new Error('User is not eligible for GenAI');
    });
  }

  /**
   * @description
   * Get if User is eligible for GenAI features
   * @method
   * @param {useCache} - If true, the cached value will be returned if available.
   * @param {checkIfNonType1UserGenAIEligible} - A callback function that clients can pass to override eligibility checks for non-T1 users.
   * @returns {Promise} - Resolves true|false
   * Rejects with an error object with the reason for ineligibility
   * @public
   */

  getIsGenAIEligible(useCache = true, useV2 = false, checkIfNonType1UserGenAIEligible = useV2API => this._isNonType1UserGenAIEligible(useV2API)) {
    if (useCache && isGenAIEligiblePromise) return isGenAIEligiblePromise;
    isGenAIEligiblePromise = new Promise((resolve, reject) => {
      if (!auth2.isSignedIn) {
        return reject(new Error('User is not signed in'));
      }
      const userProfile = auth2.getUserProfile();
      const email = userProfile.email;
      const domain = email.split('@').pop().toLowerCase();
      const isAdobeInternalUser = domain === 'adobe.com' || domain === 'adobetest.com';

      if (!isAdobeInternalUser && userProfile.account_type !== 'type1') {
        return checkIfNonType1UserGenAIEligible(useV2).then(resp => resolve(resp)).catch(err => reject(err));
      }

      return this.getIdentity().then(identity => {
        const language = identity.language;
        if (language.split('-')[0] !== 'en') {
          return reject(new Error(`User locale is non-english: ${language}`));
        }
        const getGeoLocationPromise = this.getUserGeoLocation();
        return this.getLimitsAcrobat().then(acrobatLimits => {
          if (!useV2 && (acrobatLimits.acrobat_pro !== true && acrobatLimits.acrobat_std !== true)) {
            return reject(new Error('User is not an Acrobat Std or Pro user'));
          }
          return getGeoLocationPromise
            .then(geoData => {
              if (!geoData || BLOCK_LISTED_COUNTRY_CODES.includes(geoData.country)) {
                return reject(new Error(`User is from ${geoData.country} which is a blocked country`));
              }
              return resolve(true);
            }).catch(err => {
              isGenAIEligiblePromise = undefined;
              reject(err);
            });
        }).catch(err => {
          isGenAIEligiblePromise = undefined;
          reject(err);
        });
      });
    });
    return isGenAIEligiblePromise;
  }

  /**
   * @description
   * Fetch profile picture of the user
   * @method
   * @returns {Promise} - If successful this resolves with the user's public profile images in different sizes.
   * If there is a failure, the promise will be rejected with an error object.
   * @public
   */
  async fetchUserProfilePicture(userID) {
    try {
      if (!auth2.isSignedIn) {
        throw new Error('User is not Signed-In');
      }
      const clientId = auth2.getClientID();
      const headers = {
        Accept: 'application/json',
        'x-api-key': clientId,
      };
      const ppsBaseUri = getEnvVar('pps_uri');
      const token = await getAccessToken();
      if (token) {
        headers.Authorization = token;
      }
      const response = await http.get(`${ppsBaseUri}/api/profile/${userID}`, { headers });
      if (response.status === 200 || response.status === 203) {
        const content = JSON.parse(response.content);
        return content.images;
      }
      throw new Error(`Fetch User Profile Image Failed: {"status": ${response.status},"statusText": "${response.statusText}"}`);
    } catch (error) {
      this.profilePictureMap[userID] = null;
      throw error;
    }
  }

  /**
   * @description
   * Gets the profile picture of the user from the cache if present, else makes a GET request
   * @method
   * @returns {Promise} - If successful this resolves with the user's public profile images in different sizes.
   * If there is a failure, the promise will be rejected with an error object.
   * API doc: https://identity.corp.adobe.com/pps/docs/#/Images/getImage
   * @public
   */
  getUserProfilePicture(userID) {
    if (!this.profilePictureMap) {
      this.profilePictureMap = new Map();
    }
    if (this.profilePictureMap[userID]) {
      return this.profilePictureMap[userID];
    }
    const profilePicturePromise = this.fetchUserProfilePicture(userID);
    this.profilePictureMap[userID] = profilePicturePromise;
    return profilePicturePromise;
  }
}

// This allows for providers.x().then() to be called before providers.x(config).
UserAPI.getInstance = getSingletonFunction(UserAPI);
export default UserAPI;
