/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import uuidV4 from "uuid/v4";
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";

const PROMISE_ACTION = {
    INIT: "init",
    RESOLVE: "resolve",
    REJECT: "reject",
};

export default class MessagingService {
    constructor() {
        this._messageSender = undefined;
        this._messageReceiver = undefined;
        this._isReceiverAliveCB = undefined;
        this._listenOnElement = undefined;
        this._queue = [];
        this._sessionId = "";
        this._receiveMessageHelper = this._receiveMessageHelper.bind(this);
        this._handlePromiseMessage = this._handlePromiseMessage.bind(this);
        this._promiseMap = {};
        this._promiseResolveMap = {};
        this._promiseRejectMap = {};
        this._typeListeners = {};
    }

    setSessionId(sessionId) {
        this._sessionId = sessionId;
    }

    initialize(messageSender, messageReceiver, listenOnElement, isReceiverAliveCB) {
        this.setMessageReceiver(messageReceiver, listenOnElement, isReceiverAliveCB);
        this.setMessageSender(messageSender);
    }

    setMessageReceiver(messageReceiver, listenOnElement, isReceiverAliveCB) {
        this._messageReceiver = messageReceiver;
        this._listenOnElement = listenOnElement;
        this._isReceiverAliveCB = isReceiverAliveCB;
        this._bindReceiver();
        this.listenForType(IFrameMessageTypes.PROMISE_MESSAGE, this._handlePromiseMessage);
    }

    setMessageSender(messageSender) {
        this._messageSender = messageSender;
        if (this._messageSender && this._queue.length > 0) {
            this._queue.forEach(data => {
                this._sendMessageHelper(data);
            });
            this._queue = [];
        }
    }

    setDOMEventListenerService(service) {
        this._dels = service;
    }

    _bindReceiver() {
        if (this._dels) {
            this._dels.listen(
                this._listenOnElement,
                "message",
                this._receiveMessageHelper,
            );
        } else {
            this._listenOnElement.addEventListener("message", this._receiveMessageHelper);
        }
    }

    _receiveMessageHelper(event) {
        if (!event || !event.data || !event.data.type || !event.data.sessionId) {
            // Event don't belong to us
            return;
        }

        let eventOrigin = event.origin;
        if (!eventOrigin || eventOrigin === "null" || eventOrigin === "file:") { // Edge is culprit
            eventOrigin = "";
        }

        const data = event.data;
        if (this._sessionId !== data.sessionId) {
            return; // Message doesn't belong to my session.
        }

        if (this._isReceiverAliveCB && !this._isReceiverAliveCB()) {
            // This check will ensure, we don't loose to race condition of removeListener execution
            return;
        }

        const listenerList = this._typeListeners[data.type];
        let typeData = {
            eventOrigin,
        };

        if (data.typeData) {
            typeData = { ...data.typeData, ...typeData };
        }

        if (listenerList && listenerList.length > 0) {
            listenerList.forEach(listener => listener(typeData));
        }

        this._messageReceiver(data.type, typeData);
    }

    _handlePromiseMessage(typeData) {
        const action = typeData.action;
        const promiseId = typeData.promiseId;
        if (action === PROMISE_ACTION.INIT) {
            this._promiseMap[promiseId] = new Promise((resolve, reject) => {
                this._promiseResolveMap[promiseId] = resolve;
                this._promiseRejectMap[promiseId] = reject;
            });
        } else if (action === PROMISE_ACTION.RESOLVE) {
            if (this._promiseResolveMap[promiseId]) {
                this._promiseResolveMap[promiseId](typeData.data);
            }
        } else if (action === PROMISE_ACTION.REJECT) {
            if (this._promiseRejectMap[promiseId]) {
                this._promiseRejectMap[promiseId](typeData.error);
            }
        }
    }

    listenForType(type, listener) {
        if (!this._typeListeners[type]) {
            this._typeListeners[type] = [];
        }
        this._typeListeners[type].push(listener);
    }

    sendMessage(type, typeData) {
        const data = {
            type,
            typeData,
            sessionId: this._sessionId,
        };
        this._sendMessageHelper(data);
    }

    _sendMessageHelper(data) {
        if (!this._messageSender) {
            this._queue.push(data);
        } else {
            this._messageSender(data);
        }
    }

    sendPromise(promiseInstance) {
        const promiseId = uuidV4();
        const type = IFrameMessageTypes.PROMISE_MESSAGE;
        promiseInstance.then(data => {
            this.sendMessage(type, {
                action: PROMISE_ACTION.RESOLVE,
                promiseId,
                data,
            });
        }).catch(error => {
            this.sendMessage(type, {
                action: PROMISE_ACTION.REJECT,
                promiseId,
                error,
            });
        });
        this.sendMessage(type, {
            action: PROMISE_ACTION.INIT,
            promiseId,
        });
        return promiseId;
    }

    receivePromise(promiseId) {
        return this._promiseMap[promiseId];
    }
}
