/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";
import o9n from "o9n";
import { addFullScreenToElement, removeFullScreenFromElement } from "interface/util/StylesUtil";

export default class FullScreenMessageHandler {
    constructor() {
        this._inFullScreen = false;
    }

    initialize(divId, messagingService) {
        this._divId = divId;
        messagingService.listenForType(
            IFrameMessageTypes.FULL_SCREEN_TOGGLE,
            this._fullScreenMessageReceived,
        );
    }

    isFullScreen() {
        return this._inFullScreen;
    }

    _fullScreenMessageReceived = typeData => {
        this._inFullScreen = typeData.inFullScreen;
        const divElement = window.document.getElementById(this._divId);
        if (typeData.inFullScreen) {
            if (typeData.isOnDevice) {
                o9n.orientation.lock("landscape-primary");
            }
            if (typeData.switchToFullWindow) {
                this.fullScreenOn(divElement, typeData.isIOS);
            }
        } else {
            if (typeData.isOnDevice) {
                o9n.orientation.unlock();
            }
            if (typeData.switchToFullWindow) {
                this.fullScreenOff(divElement, typeData.isIOS);
            }
        }
    };

    fullScreenOn = (divElement, isIOS) => {
        addFullScreenToElement(divElement, isIOS);
        if (isIOS) {
            // This is for 100vh iPad Safari bug
            // First we get the viewport height and we multiple it by 1% to get a value
            // for a ViewSDK-mobile-viewport-height unit
            // Then we set the value in the --ViewSDK-mobile-viewport-height custom property to the root of the document
            this.iosHeightInterval = setInterval(() => {
                document.documentElement.style
                    .setProperty("--ViewSDK-mobile-viewport-height", `${window.innerHeight * 0.01}px`);
            }, 50);
        }
    };

    fullScreenOff = (divElement, isIOS) => {
        removeFullScreenFromElement(divElement, isIOS);
        if (isIOS && this.iosHeightInterval) {
            clearInterval(this.iosHeightInterval);
        }
    };
}
