/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* eslint no-extra-bind: 0, no-use-before-define: 0 */
/* eslint-disable max-classes-per-file */
/* global window */
/**
 * @file
 */

import { http } from 'adobe-dcapi-web';
import uuidv4 from 'uuid/v4';
import { cloneDeep } from '../core/CommonUtil';
import { auth2 } from './Auth2API';
import DcapiAPI from './DcapiAPI';

const TEXT_LIMIT = 2048;
const SERVER_PATH = '/system/log';
const SERVER_CONTENT_TYPE = 'application/vnd.adobe.dc+json; profile="/schemas/system_log_parameters_v1.json"';
const DEFER_TIMEOUT = 30000;
const DEFAULT_ASYNC_INTERVAL = 10000;
const DEFAULT_MAX_BATCH_LENGTH = 10240;

// registered message listeners
let listeners = [];

let currentBatchLength = 0;

const operation = {
  system: { log: 'system.log' },
};
const callContent = {
  system_log_parameters: 'system_log_parameters_v1.json',
};

let dcapi;

// TODO: Re-enable if needed
// const loggers = {};

const isValidLevel = level => ['debug', 'info', 'warn', 'error'].indexOf(level) !== -1;

class Logger {
  /**
   * @description
   * Create the singleton logging object.
   *
   * @constructor
   * @param {object} context - The context used at the begining of all messages.
   * The purpose of the context is to accept name: value pairs that will be added
   * as name=value in the logs rather than embedded in the message, and can accepts
   * function values to be used at run-time. There's a special-case of the context
   * being a string, in which case it becomes {"context": givenContext}.
   * @param {object} config - The config for the logging API.
   * The LoggingAPI can be configured based on a default or given configuration object
   * by calling the configure() function.
   * The configuration is passed to the Logger constructor by the getLogger() function.
   * The API supports configuration of handlers to send logs to a server or console stream.
   * It supports listening for window-level errors, and unhandled Promise rejection where possible.
   * The LoggingAPI supports for enabling of specific logging levels per stream.
   * It also allows for buffering logs that will be sent later to the server.
   * @param {object} options - Options for additional logger specific configuration parameters.
   * enableStream is a string or array of strings that if it exists will only enable
   * the stream(s) names that match. Current streams are 'console' and 'server'.
   * enableLevel is a string or array of strings that if it exists will only enable
   * the message levels that match. Current levels are 'debug', 'info', 'warn', and 'error'.
   */
  constructor(context, config, options) {
    this.context = typeof context === 'object' ? context : { context };
    this.config = config || cloneDeep(LoggingAPI.defaultConfig);
    this.options = options || {};
    // Lazy load dcapi so it can be used for auth logging
    if (auth2.isSignedIn) {
      DcapiAPI.getInstance().then(dcapiInstance => {
        dcapi = dcapiInstance.getDcapi();
      });
    }
  }

  /**
   * @description
   * Bind methods to handle new Promise(...).then(logger.info, logger.error).
   * @method
   * @param {string} level - The level on which log must be logged
   * @param {object} [additional params] - Any additional parameters to pass into the method.
   */
  doLog = (level, ...args) => {
    if (!args.length || !args[0]) {
      return;
    }
    listeners.forEach(listener => listener(this.context, level, ...args));

    // Keep this lean and pass through the arguments, since they may be many,
    // e.g. logger.debug("user_id: %s, context: %s, email: %s", user.id, user.context, user.email);
    Object.entries(this.config.streams).forEach(([key, stream]) => {
      // check Logger options to see if stream is enabled
      if (this.options.enableStream) {
        if (typeof this.options.enableStream === 'object'
          ? this.options.enableStream.indexOf(key) === -1
          : this.options.enableStream !== key) {
          return;
        }
      }

      // check Logger options to see if level is enabled
      if (this.options.enableLevel) {
        if (typeof this.options.enableLevel === 'object'
          ? this.options.enableLevel.indexOf(level) === -1
          : this.options.enableLevel !== level) {
          return;
        }
      }

      // Defer all logs to be asynchronously logged in batch at an interval
      // Immediately log the console stream, once the startup deferral is over
      if (stream.enable && stream.enable(this.config, this.context, level, ...args)) {
        if (key === 'console' && !stream.deferUntil) {
          stream.handler(this.config, this.context, level, ...args);
          return;
        }
        const context = this.context && this.context.client_timestamp
          ? this.context
          : ({ client_timestamp: new Date().toISOString(), ...this.context });
        LoggingAPI.doDefer(stream, this.config, context, level, ...args);
      }
    });

    // Flush all streams immediately on error, in case the error results in a page refresh/redirect
    if (level === 'error') {
      LoggingAPI.flushAll(this.config);
    }
  };

  /**
   * @method
   * @param {object} [additional params] - Any additional parameters to pass into the method.
   */
  debug = (...args) => this.doLog('debug', ...args);

  /**
   * @method
   * @param {object} [additional params] - Any additional parameters to pass into the method.
   */
  info = (...args) => this.doLog('info', ...args);

  /**
   * @method
   * @param {object} [additional params] - Any additional parameters to pass into the method.
   */
  warn = (...args) => this.doLog('warn', ...args);

  /**
   * @method
   * @param {object} [additional params] - Any additional parameters to pass into the method.
   */
  error = (...args) => this.doLog('error', ...args);

  /**
   * @method
   * @param {string} message - Required for /system/log endpoint. Message to be logged.
   * @param {object} params - Object containing top-level properties to be logged as
   * siblings to the message.
   * The message param will be set as a property of the params object.
   */
  emit = (message, params = {}) => {
    params.message = message;
    const level = (params.level && isValidLevel(params.level)) ? params.level : 'info';
    delete params.level;
    return this.doLog(level, params);
  };
}

/**
 * @method
 * @param {object} config
 * @param {string} context
 * @param {string} level
 * @param {object} [additional params]
 * @returns {boolean}
 * @public
 */
// function consoleEnable(config, context, level, ...args) {
function consoleEnable() {
  /* jslint unparam:true */
  return true;
}

/**
 * @method
 * @param {object} config
 * @param {string} context
 * @param {string} level
 * @param {object} [additional params]
 * @public
 */
function consoleHandler(config, context, level, ...args) {
  const consoleLevel = level === 'debug' ? 'log' : level;
  if (console[consoleLevel]) {
    const ctx = LoggingAPI.contextStringify(context);
    if (args[0].message) {
      // Using logger.emit endpoint
      args = [ctx, ...args];
    } else {
      // Any other endpoint
      args[0] = `${ctx} ${args[0]}`;
    }
    // Now this should go directly to the console as naturally supported.
    console[consoleLevel](...args);
  }
  return Promise.resolve();
}

/**
 * @method
 * @param {object} config
 * @param {string} context
 * @param {string} level
 * @param {object} [additional params]
 * @returns {boolean}
 * @public
 */
// function serverEnable(config, context, level, ...args) {
function serverEnable() {
  /* jslint unparam:true */
  return false;
}

/**
 * Obtain Error instance from log if present
 * @param {*} log
 * @returns Error instance or undefined if no error is found
 */
function getErrorFromLog(log) {
  if (log instanceof Error) {
    return log;
  }

  if (log.message instanceof Error) {
    return log.message;
  }
}

/**
 * Tries to extract more data from error object
 * @param {*} error - Error object
 * @param {Array} logData - Remaining log properties
 * @returns {object} Returns serialized error log object
 */
function extractErrorData(log, logData) {
  const error = getErrorFromLog(log);
  if (!error) return log;

  const {
    name,
    cause,
    stack,
    fileName,
    lineNumber,
  } = error;

  // check if we have errorInfo from ErrorBoundary
  const componentStack = logData?.[0]?.componentStack;

  return {
    name,
    message: String(error),
    stack,
    cause,
    fileName,
    lineNumber,
    componentStack,
  };
}

/**
 * @description Hydrate log message with context
 * @method
 * @param {string} context
 * @param {string} level
 * @param {object} log
 * @param {number} index
 * @returns {object} Returns null when log message does not exist
 *                   otherwise the result of merging context into log message.
 * @private
 */
function hydrateLogMessageWithContext(context, level, log, index) {
  const message = extractErrorData(
    log[index].message
      ? log[index] // Emit path
      : { message: LoggingAPI.truncate(LoggingAPI.format(...log.slice(index))) },
    log.slice(index + 1),
  );

  const result = { level, ...message };
  if (!result.message || Object.keys(result.message).length === 0) {
    return null;
  }
  return LoggingAPI.contextObjAppend(result, context);
}

/**
 * @method
 * @param {object} config
 * @param {string} context
 * @param {string} level
 * @param {object} [additional params]
 * @public
 */
function serverHandler(config, context, level, ...args) {
  const data = hydrateLogMessageWithContext(context, level, args, 0);
  if (!data) {
    return null;
  }
  return _logToServer(config, data);
}

/**
 * @method
 * @param {array} logs
 *     The details of the messages to log as an array of arrays.
 *     Format: [[config, context, level, message, ...args], [config, context, level, message, ...args], ...]
 * @public
 */
function serverBatchHandler(logs) {
  if (logs.length === 0) {
    return;
  }

  const data = [];
  logs.forEach(log => {
    const item = hydrateLogMessageWithContext(log[1], log[2], log, 3);
    if (!item) {
      return;
    }
    data.push(item);
  });

  return _logToServer(logs[0][0], data);
}

/**
 * @method
 * @param {object} config The configuration for this logging event
 * @param {object} data The data to log to the server
 * @private
 */
function _logToServer(config, data) {
  const userProfile = window.adobeIMS ? auth2.getUserProfile() : {};
  if (dcapi && auth2.isSignedIn && !auth2.isSigningOut && userProfile) {
    return _logWithAuth(data, userProfile);
  }
  return _logWithoutAuth(data, config);
}

/**
 * @method
 * @param {object} options
 * @param {object} data
 * @param {object} config
 * @private
 */
function _logWithoutAuth(data, config) {
  const options = {
    headers: {
      'Content-Type': config.serverContentType,
      'x-request-id': uuidv4(),
      'x-api-app-info': config.serverAppInfo || 'LoggingAPI',
      'x-api-client-id': config.serverApiClient || 'api_browser',
    },
  };
  return http.post(config.serverOrigin + SERVER_PATH, options, JSON.stringify(data))
    .catch(err => {
      // Catch the server error to avoid it looping as an unhandled error.
      // Use a warning since it's not fatal to the main flow, just the error reporting flow.
      console.warn('Error in Logging serverHandler:', err);
      return err;
    });
}

/**
 * @method
 * @param {object} data
 * @param {object} userProfile
 * @private
 */
function _logWithAuth(data, userProfile) {
  const countryCode = userProfile.countryCode || 'unknown';
  if (data instanceof Array) {
    data.forEach(item => item.country_code = countryCode);
  } else {
    data.country_code = countryCode;
  }

  return dcapi.call(operation.system.log, {
    content_type: callContent.system_log_parameters,
    content: JSON.stringify(data),
  }).catch(err => {
    // Catch the server error to avoid it looping as an unhandled error.
    // Use a warning since it's not fatal to the main flow, just the error reporting flow.
    console.warn('Error in Logging serverHandler:', err);
    return err;
  });
}

/**
 * @classdesc
 * LoggingAPI Provider, use getLogger(context) where context is typically the class or module name.
 * The constructor can take a configuration object, which can also be set using the configure(function):
 * @class
 * @example
 * logging = new LoggingAPI(Object.assign({
 *     serverOrigin: "https://dc-api-stage.adobe.io",
 *     serverAppInfo: "LoggingAPI-test"
 * }, LoggingAPI.defaultConfig);
 *
 * where the above is equivalent to:
 * @example
 * logging = new LoggingAPI();
 * logging.configure(function (config) {
 *     config.serverOrigin: "https://dc-api-stage.adobe.io";
 *     config.serverAppInfo: "LoggingAPI-test";
 * });
 */
class LoggingAPI {
  /**
   * Default configuration for the LoggingAPI.
   * Define listeners to unhandled Promise rejections and window errors.
   * Define default console and server stream handlers.
   */
  static defaultConfig = {
    listeners: {
      unhandledrejection(ev) {
        LoggingAPI.flushAll(this.config);
        const reason = (ev.reason && ev.reason.stack) ? ev.reason.stack : JSON.stringify(ev.reason || ev);
        this.unhandledRejectionLogger.error('%s:%s', ev.type, reason);
      },
      unhandlederror(message, file, line, col, error) {
        LoggingAPI.flushAll(this.config);
        this.unhandledErrorLogger.error('%o', {
          message, file, line, col, error,
        });
      },
      beforeunload() {
        LoggingAPI.flushAll(this.config);
      },
    },
    streams: {
      console: {
        enable: consoleEnable,
        handler: consoleHandler,
        deferUntil: undefined,
        deferredLogs: [],
      },
      server: {
        enable: serverEnable,
        handler: serverHandler,
        batchHandler: serverBatchHandler,
        deferUntil: undefined,
        deferredLogs: [],
      },
    },
    defaultTimeout: DEFER_TIMEOUT,
    asyncInterval: DEFAULT_ASYNC_INTERVAL,
    maxBatchLength: DEFAULT_MAX_BATCH_LENGTH,
  };

  /**
   * @constructor
   * @param {object} config - The config for the logging API.
   */
  constructor(config) {
    this.config = config || cloneDeep(LoggingAPI.defaultConfig);
  }

  /**
   * @description
   * Add a listener for all log events.  This will allow our test harness to detect
   * any instances of error messages that should be flagged for test failure.
   * @method
   * @param {function} listener - a function to be called with every message.
   * accepts the parameters: listener(level, context, ...args)
   */
  addListener(listener) {
    listeners.push(listener);
  }

  /**
   * @description
   * Remove a log event listener -- previously added via addListener.
   * any instances of error messages that should be flagged for test failure.
   * @method
   * @param {function} listener - the listener method to remove.
   */
  removeListener(listener) {
    listeners = listeners.filter(lstnr => lstnr !== listener);
  }

  /**
   * @description
   * To configure the LoggingAPI instance with provided configuration function
   * @method
   * @param {function} configFunc - The function to configure the LoggingAPI instance.
   * @returns {LoggingAPI} - Current LoggingAPI instance after it is configured with given function.
   * @public
   */
  configure(configFunc) {
    const vm = window.adobe_dc_sdk.version_manifest || window.adobe_dc_sdk.cache.version_manifest;
    this.config.asyncInterval = vm['dc-core'].providers.logging.logging_async_interval
          || DEFAULT_ASYNC_INTERVAL;

    if (configFunc) {
      configFunc(this.config);
    }

    const config = this.config;
    if (config.serverOrigin) {
      config.serverContentType = SERVER_CONTENT_TYPE.replace('/schemas/', `${config.serverOrigin}/schemas/`);
    }

    if (typeof window === 'object' && config && config.listeners) {
      this.unhandledRejectionLogger = this.getLogger('UnhandledRejection');
      this.unhandledErrorLogger = this.getLogger('UnhandledError');
      if (config.listeners.unhandledrejection) {
        // Chrome supports this window listener, but not many other browsers.
        window.addEventListener('unhandledrejection', config.listeners.unhandledrejection.bind(this), false);
      }
      if (config.listeners.errorListener) {
        // window.onerror() is more useful since addEventListener("error") may not have stack info.
        // Chrome, Firefox, and MSIE 11 will populate the error, but not MS-Edge.
        window.onerror = config.listeners.errorListener.bind(this);
      }
      if (config.listeners.beforeunload) {
        window.addEventListener('beforeunload', config.listeners.beforeunload.bind(this), false);
      }
    }

    // Handle a deferUntil on a stream (console or server stream).
    Object.values(config.streams).forEach(stream => {
      if (
        stream.deferUntil
              && typeof stream.deferUntil === 'object'
              && typeof stream.deferUntil.then === 'function'
      ) {
        LoggingAPI.deferTimeout(stream, config.defaultTimeout, stream.deferUntil)
          .then(
            () => LoggingAPI.flushLogs(stream),
            () => LoggingAPI.flushLogs(stream),
          );
      }
    });

    // Set the interval for logging
    setInterval(() => {
      if (!Object.values(config.streams).some(s => s.deferUntil)) {
        LoggingAPI.flushAll(config);
      }
    }, config.asyncInterval);

    // Return this to allow tailing calls.
    return this;
  }

  /**
   * @description
   * To get instance of the Logger based on context provided.
   * @method
   * @param {object} context - The context used at the begining of all messages.
   * @param {object} options - Options for general logger configuration.
   * @return {Logger} - Instance of Logger.
   * @public
   */
  getLogger(context, options) {
    return new Logger(context, this.config, options);
  }

  /**
   * @description
   * Flush the queues for all streams
   * @method
   * @public
   */
  static flushAll(config) {
    Object.values(config.streams).forEach(stream => {
      this.flushLogs(stream);
    });
  }

  /**
   * @description
   * Flush the queues of a stream and cancel the timer
   * @method
   * @param {object} stream - the stream to flush
   * @public
   */
  static flushLogs(stream) {
    // Flush the logs to the appropriate stream.
    if (stream.deferredLogs) {
      if (stream.batchHandler) {
        stream.batchHandler(stream.deferredLogs);
      } else {
        stream.deferredLogs.forEach(logArgs => {
          stream.handler(...logArgs);
        });
      }
      stream.deferredLogs = [];
    }

    if (stream.deferUntil) {
      stream.deferUntil = null;
      if (stream.timeoutID) {
        clearTimeout(stream.timeoutID);
        stream.timeoutID = null;
      }
    }
  }

  /**
   * @description
   * Strip the path leaving a filename to use as the logging context name.
   * @method
   * @param {string} filename - The filename from which we extract the path.
   * @returns {boolean}
   * @example
   * e.g. logging.getLogger(LoggingAPI.stripPath(__filename));
   * @public
   */
  static stripPath(filename) {
    const match = /[\w+\-.]+$/.exec(filename);
    return match && match[0];
  }

  /**
   * @description
   * Truncate the text to the allowed limit.
   * @method
   * @param {string} text - The text to be truncated
   * @returns {string} - The truncated text
   * @public
   */
  static truncate(text) {
    if (text.length > TEXT_LIMIT) {
      text = `${text.substring(0, TEXT_LIMIT - 3)}...`;
    }
    return text;
  }

  /**
   * @description
   * Convert an object to a key=value string if given an object, otherwise return what's given.
   * @method
   * @param {object} obj - An object to be parsed to string
   * @returns {string} - The string containing parsed version of the object.
   * @public
   */
  static contextStringify(obj) {
    const ary = [];
    if (typeof obj === 'object') {
      Object.keys(obj).forEach(key => {
        ary.push(`${String(key)}=${typeof obj[key] === 'function' ? String(obj[key]()) : String(obj[key])}`);
      });
    } else {
      ary.push(obj);
    }

    return ary.join(' ');
  }

  /**
   * @description
   * Append the obj to the given result, replacing any functions with the result of calling them.
   * @method
   * @param {object} result - The result for which any obect is to be added
   * @param {object} obj - The object that is to be added to the result
   * @returns {object} - The result after merging the provided object into the result.
   * @public
   *
   */
  static contextObjAppend(result, obj) {
    if (typeof obj === 'object') {
      Object.keys(obj).forEach(key => {
        result[key] = typeof obj[key] === 'function' ? obj[key]() : obj[key];
      });
    } else {
      result.context = obj;
    }
    return result;
  }

  /**
   * @description
   * Format args according to the browser standard:
   * @see
   * https://console.spec.whatwg.org/#formatting-specifiers
   * Useful when sending to the server, the browser will to this naturally.
   * @method
   * @returns {string} - formatted message
   * @public
   */
  static format(...args) {
    if (args.length === 1) {
      return args[0];
    }

    const fmt = args[0];
    const find = /%[%dfiOos]/g;
    const ary = [];
    let match = null;
    let i = 1;
    let pos = 0;
    let str;

    // eslint-disable-next-line
    while (i < args.length && (match = find.exec(fmt)) !== null) {
      str = match[0];
      ary.push(fmt.substring(pos, match.index));
      pos = find.lastIndex;
      switch (str) {
      case '%s':
        ary.push(String(args[i]));
        break;
      case '%d':
      case '%i':
        ary.push(parseInt(args[i], 10));
        break;
      case '%f':
        ary.push(parseFloat(args[i]));
        break;
      case '%o':
      case '%O':
        ary.push(JSON.stringify(args[i]));
        break;
      default:
        ary.push(str);
      }
      ++i;
    }

    if (typeof fmt === 'string' && find.lastIndex < fmt.length) {
      ary.push(fmt.substring(find.lastIndex));
    }
    while (i < args.length) {
      ary.push(` ${String(args[i++])}`);
    }
    return ary.join('');
  }

  /**
   * @description
   * Defer the log message by pushing onto the array
   * @method
   * @param {object} stream
   * @param {object} [additional params]
   * @public
   */
  static doDefer(stream, ...args) {
    if (stream.deferredLogs) {
      if (args.length === 4) {
        // If batch size has reach 10K, flush the logs
        // This avoids sending large packets of logs to logging server
        let payload;
        try {
          payload = JSON.stringify(args[3]);
        } catch (e) {
          console.warn('Error in stringifying the log:', e);
          return;
        }
        const newBatchLength = payload.length + currentBatchLength;
        const maxBatchLength = args[0].maxBatchLength || DEFAULT_MAX_BATCH_LENGTH;
        if ((currentBatchLength > maxBatchLength) || (newBatchLength > maxBatchLength)) {
          LoggingAPI.flushAll(args[0]);
          currentBatchLength = payload.length;
        } else {
          currentBatchLength = payload.length + currentBatchLength;
        }
      }
      stream.deferredLogs.push(args);
    }
  }

  /**
   * @description
   * Applies a timeout to a promise
   * @method
   * @param {object} stream - the stream that log is sent to
   * @param {int} timeout - the maximum time to wait in <ms> milliseconds.
   * @param {Promise} promise - promise that resolves when app has finished loading.
   * @public
   */
  static deferTimeout(stream, timeoutPeriod, promise) {
    // Create a promise that rejects in <ms> milliseconds
    const timeoutPromise = new Promise((resolve, reject) => {
      stream.timeoutID = setTimeout(() => {
        stream.timeoutID = null;
        reject(new Error('error.deferred.logging.timeout'));
      }, timeoutPeriod);
    });
      // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeoutPromise]);
  }
}

export const logging = new LoggingAPI();
export default LoggingAPI;
