/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import uuidV4 from "uuid/v4";
import { getCurrentEnv } from "bundle/util/EnvUtil";

const ALLOWED_LOCALE_TO_USER_VOICE_MAP = {
    "cs-CZ": "cs",
    "ko-KR": "ko",
    "pl-PL": "pl",
    "ru-RU": "ru",
    "tr-TR": "tr",
    "zh-CN": "zh",
    "zh-TW": "zh",
    "zz-ZZ": "zz",
    "en-US": "en",
    "ja-JP": "ja",
    "fr-FR": "fr",
    "de-DE": "de",
    "da-DK": "da",
    "en-GB": "en",
    "es-ES": "es",
    "fi-FI": "fi",
    "it-IT": "it",
    "nb-NO": "nb",
    "nl-NL": "nl",
    "pt-BR": "pt-BR",
    "sv-SE": "sv-SE",
};

const DEFAULT_LOCALE = "en-US";

const INITIAL_CONFIG = {
    locale: "en-US",
    divId: "adobe-dc-view",
    logToConsole: false,
    downloadWithCredentials: false,
    sendAutoPDFAnalytics: true,
};

const ENV_CONFIG = {
    local: {
        allowQueryPassing: true,
    },
    dev: {
        allowQueryPassing: true,
    },
    stage: {
        allowQueryPassing: true,
    },
    prod: {
        allowQueryPassing: false,
    },
};

const getFinalLocale = inLocale => {
    if (inLocale.indexOf("-") < 0) {
        return DEFAULT_LOCALE;
    }

    const localeMap = inLocale.split("-");
    const normalizedLocale = `${ localeMap[0].toLowerCase() }-${ localeMap[1].toUpperCase() }`;
    if (Object.keys(ALLOWED_LOCALE_TO_USER_VOICE_MAP).indexOf(normalizedLocale) < 0) {
        return DEFAULT_LOCALE;
    }

    return normalizedLocale;
};

const getHash = str => {
    if (!str) {
        return "";
    }
    let hash = "";
    let s = 0;
    while (s < str.length) {
        hash = `${hash}${str.charCodeAt(s)}`;
        s += 1;
    }
    return hash;
};

const getScriptSrc = () => {
    let ownSrc;
    const scripts = window.document.scripts;
    [].forEach.call(scripts, script => {
        const src = script.src;
        if (src && src.indexOf("ViewSDKInterface.js") !== -1) {
            ownSrc = src.replace("ViewSDKInterface.js", "");
        }
        if (src && src.indexOf("EditSDKInterface.js") !== -1) {
            ownSrc = src.replace("EditSDKInterface.js", "");
        }
    });
    return ownSrc;
};

const getAllowedQueryString = () => {
    const allowedParams = ["dropin!", "perfServiceHost", "provider!",
        "file", "name", "enableLogging", "sessionId", "testVerifyFlow"];
    const searchSplit = window.location.search.substring(1).split("&");
    let finalSearchStr = "";
    let separator = "";
    searchSplit.forEach(kvPair => {
        const pair = kvPair.split("=");
        let allowed = false;
        allowedParams.forEach(param => {
            if (pair[0].indexOf(param) !== -1) {
                allowed = true;
            }
        });
        if (allowed) {
            finalSearchStr = `${ finalSearchStr }${ separator }${ pair[0] }=${ pair[1] }`;
            separator = "&";
        }
    });

    if (finalSearchStr.length > 0) {
        finalSearchStr = `?${ finalSearchStr }`;
    }

    return finalSearchStr;
};

const _assignEnvConfig = config => {
    const envConfig = ENV_CONFIG[getCurrentEnv(config.ownSrc)];
    return { ...config, ...envConfig };
};

const _assignIFrameConfig = config => {
    const iframeConfig = {};
    iframeConfig.iframeId = `iframe-${ config.divId }`;
    let searchStr = config.allowQueryPassing ? getAllowedQueryString() : "";
    if (searchStr) {
        searchStr += `&locale=${ config.locale }`;
    } else {
        searchStr += `?locale=${ config.locale }`;
    }
    if (config.logToConsole) {
        searchStr += "&logToConsole";
    }
    if (config.msi) {
        searchStr += `&msi=${ config.msi }`;
    }
    searchStr += `&parent=${ encodeURIComponent(window.location.href) }`;
    let iframeHtml = "iframe.html";
    const env = `${getCurrentEnv(config.ownSrc)}`;
    if (env !== "prod") {
        iframeHtml = `private/iframe_${env}.html`;
    }
    iframeConfig.iframeSource = `${ config.ownSrc }${ iframeHtml }${ searchStr }`;
    return { ...iframeConfig, ...config };
};

const getFinalConfig = config => {
    let finalConfig = { ...INITIAL_CONFIG, ...config };
    finalConfig.msi = getHash(finalConfig.divId);
    if (!finalConfig.sessionId) {
        finalConfig.sessionId = uuidV4();
    }
    finalConfig.locale = getFinalLocale(finalConfig.locale);
    finalConfig.userVoiceLocale = ALLOWED_LOCALE_TO_USER_VOICE_MAP[finalConfig.locale];
    finalConfig.ownSrc = getScriptSrc();
    finalConfig = _assignEnvConfig(finalConfig);
    finalConfig = _assignIFrameConfig(finalConfig);
    return finalConfig;
};

export default getFinalConfig;
