/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/**
 * @description
 * Document Cloud React Spectrum V3 Theme package.
 */

import { theme as defaultTheme } from '@react-spectrum/theme-default';
import { theme as darkTheme } from '@react-spectrum/theme-dark';

const dcV3Theme = {
  ...defaultTheme,
  dark: darkTheme.light,
};

/* eslint-disable-next-line import/prefer-default-export */
export { dcV3Theme };
