/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
/* eslint-disable class-methods-use-this */

import uuidV4 from "uuid/v4";
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";

export default class CommandSenderService {
    constructor() {
        this._promiseMap = {};
        this._promiseResolveMap = {};
        this._promiseRejectMap = {};
    }

    initialize(messagingService) {
        this._messagingService = messagingService;
        this._commandResultReceived = this._commandResultReceived.bind(this);
        this._messagingService.listenForType(IFrameMessageTypes.COMMAND_RESULT, this._commandResultReceived);
    }

    _commandResultReceived(typeData) {
        const commandId = typeData.commandId;
        if (typeData.resolve) {
            try {
                this._promiseResolveMap[commandId](typeData.resultData);
            } catch (e) {
                // do nothing
            }
        } else {
            const err = typeData.resultData;
            this._promiseRejectMap[commandId](err.code ? err : (err.message || err));
        }
    }

    sendCommand(command, commandData = {}) {
        const commandId = uuidV4();
        this._promiseMap[commandId] = new Promise((resolve, reject) => {
            this._promiseResolveMap[commandId] = resolve;
            this._promiseRejectMap[commandId] = reject;
        });
        const data = {
            commandId,
            command,
            commandData,
        };
        this._messagingService.sendMessage(IFrameMessageTypes.EXECUTE_COMMAND, data);
        return this._promiseMap[commandId];
    }
}
