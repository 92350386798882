/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

/* eslint class-methods-use-this: 0 */

import uuidV4 from "uuid/v4";
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";
import CALLBACK_ACTION from "common/constants/CallbackConstants";

export default class CallbackService {
    constructor() {
        this._callbackMap = [];
        this._callbackOptionsMap = [];
        this._callbackSuccessMap = [];
        this._callbackFailMap = [];
        this._typeRegistrationListeners = {};
        this._handleCallbackMessage = this._handleCallbackMessage.bind(this);
    }

    initialize(messagingService) {
        this._messagingService = messagingService;
        this._messagingService.listenForType(IFrameMessageTypes.CALLBACK, this._handleCallbackMessage);
    }

    _scheduleForCleanUp(callbackId) {
        setTimeout(() => {
            if (this._callbackSuccessMap[callbackId]) {
                delete this._callbackSuccessMap[callbackId];
            }
            if (this._callbackFailMap[callbackId]) {
                delete this._callbackFailMap[callbackId];
            }
        }, 5000);
    }

    _handleCallbackMessage(typeData) {
        const action = typeData.action;
        const callbackType = typeData.type;
        const callbackId = typeData.callbackId;
        const msgType = IFrameMessageTypes.CALLBACK;
        if (action === CALLBACK_ACTION.REGISTER) {
            this._callbackOptionsMap[callbackType] = typeData.options;
            this._callbackMap[callbackType] = (...args) => {
                const executionCallbackId = uuidV4();
                this._messagingService.sendMessage(msgType, {
                    action: CALLBACK_ACTION.CALL,
                    type: callbackType,
                    callbackId: executionCallbackId,
                    args,
                });
                return new Promise((resolve, reject) => {
                    this._callbackSuccessMap[executionCallbackId] = resolve;
                    this._callbackFailMap[executionCallbackId] = reject;
                });
            };
            const listenerList = this._typeRegistrationListeners[callbackType];
            if (listenerList && listenerList.length > 0) {
                listenerList.forEach(listener => listener(typeData));
            }
        } else if (action === CALLBACK_ACTION.CALL) {
            const promiseInstance = this._getCallbackPromise(typeData);
            promiseInstance.then(data => {
                this._messagingService.sendMessage(msgType, {
                    action: CALLBACK_ACTION.SUCCESS,
                    type: callbackType,
                    callbackId,
                    data,
                });
            }).catch(error => {
                this._messagingService.sendMessage(msgType, {
                    action: CALLBACK_ACTION.FAIL,
                    type: callbackType,
                    callbackId,
                    error,
                });
            });
        } else if (action === CALLBACK_ACTION.SUCCESS) {
            if (this._callbackSuccessMap[callbackId]) {
                this._callbackSuccessMap[callbackId](typeData.data);
                this._scheduleForCleanUp(callbackId);
            }
        } else if (action === CALLBACK_ACTION.FAIL) {
            if (this._callbackFailMap[callbackId]) {
                this._callbackFailMap[callbackId](typeData.error);
                this._scheduleForCleanUp(callbackId);
            }
        }
    }

    listenForTypeRegistration(type, listener) {
        if (!this._typeRegistrationListeners[type]) {
            this._typeRegistrationListeners[type] = [];
        }
        this._typeRegistrationListeners[type].push(listener);
    }

    _getCallbackPromise(typeData) {
        const type = typeData.type;
        return this._callbackMap[type](...typeData.args);
    }

    registerCallback(type, callback, options) {
        this._callbackMap[type] = callback;
        const callbackId = uuidV4();
        this._messagingService.sendMessage(IFrameMessageTypes.CALLBACK, {
            action: CALLBACK_ACTION.REGISTER,
            type,
            callbackId,
            options,
        });
    }

    hasCallback(type) {
        return this._callbackMap[type] !== undefined;
    }

    getCallback(type) {
        return this._callbackMap[type];
    }

    getCallbackOptions(type) {
        return this._callbackOptionsMap[type];
    }
}
