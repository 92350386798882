/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2021 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import { PDFAnalyticsEvents } from "bundle/constants/Events";
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";
import {
    HANDLE_EVENT_TIMEOUT,
    CLEAR_DATA_TIMEOUT,
} from "app/constants/TimeoutConstants";
import { AnalyticsActions } from "app/constants/AnalyticsConstants";

export default class PDFAnalyticsFeederService {
    constructor() {
        this._handleEvent = this._handleEvent.bind(this);
        this._addAnalyticsDataForDocumentOpen = this._addAnalyticsDataForDocumentOpen.bind(this);
        this._addAnalyticsDataForPageView = this._addAnalyticsDataForPageView.bind(this);
        this._addAnalyticsDataForDocumentDownload = this._addAnalyticsDataForDocumentDownload.bind(this);
        this._addAnalyticsDataForDocumentPrint = this._addAnalyticsDataForDocumentPrint.bind(this);
        this._addAnalyticsDataForTextSearch = this._addAnalyticsDataForTextSearch.bind(this);
        this._addAnalyticsDataForBookmarkClick = this._addAnalyticsDataForBookmarkClick.bind(this);
        this._addAnalyticsDataForHyperlinkOpen = this._addAnalyticsDataForHyperlinkOpen.bind(this);
        this._addAnalyticsDataForTextCopy = this._addAnalyticsDataForTextCopy.bind(this);
        this._addAnalyticsDataForZoomLevel = this._addAnalyticsDataForZoomLevel.bind(this);
        this._timeInterval = undefined;
        this._eventList = [];
    }

    initialize(messagingService, eventSender, { reportSuiteId, measurementId } = {}) {
        this._reportSuiteId = typeof reportSuiteId === "string" ? reportSuiteId : undefined;
        this._measurementId = typeof measurementId === "string" ? measurementId : undefined;
        messagingService.listenForType(IFrameMessageTypes.EVENT, this._handleEvent);
        this._eventSender = eventSender;
        this._eventHandlerMap = new Map([
            [PDFAnalyticsEvents.DOCUMENT_OPEN, this._addAnalyticsDataForDocumentOpen],
            [PDFAnalyticsEvents.PAGE_VIEW, this._addAnalyticsDataForPageView],
            [PDFAnalyticsEvents.DOCUMENT_DOWNLOAD, this._addAnalyticsDataForDocumentDownload],
            [PDFAnalyticsEvents.DOCUMENT_PRINT, this._addAnalyticsDataForDocumentPrint],
            [PDFAnalyticsEvents.TEXT_SEARCH, this._addAnalyticsDataForTextSearch],
            [PDFAnalyticsEvents.BOOKMARK_ITEM_CLICK, this._addAnalyticsDataForBookmarkClick],
            [PDFAnalyticsEvents.HYPERLINK_OPEN, this._addAnalyticsDataForHyperlinkOpen],
            [PDFAnalyticsEvents.TEXT_COPY, this._addAnalyticsDataForTextCopy],
            [PDFAnalyticsEvents.ZOOM_LEVEL, this._addAnalyticsDataForZoomLevel],
        ]);
    }

    // Checks whether event is for Adobe Analytics or not
    get isEventForAdobeAnalytics() {
        return !!this._reportSuiteId;
    }

    // Checks whether event is for Google Analytics or not
    get isEventForGoogleAnalytics() {
        return !!this._measurementId;
    }

    // Maintain time between sending multiple events
    _handleEvent(eventData) {
        if (PDFAnalyticsEvents[eventData.type]) {
            this._eventList.push(eventData);
            if (!this._timeInterval) {
                this._timeInterval = setInterval(() => {
                    if (!this._eventSender.canSendEvent) {
                        return; // try in another call
                    }

                    if (this._eventList.length === 0) {
                        clearInterval(this._timeInterval);
                        this._timeInterval = undefined;
                        return;
                    }

                    this._relevantDataPresent = true;
                    const data = this._processDataForAnalytics(this._eventList.shift());
                    this._sendEvent(data);
                    this._clearData();
                }, HANDLE_EVENT_TIMEOUT);
            }
        }
    }

    // Create final event data object to send to analytics platform
    _processDataForAnalytics(eventData) {
        if (!eventData?.data?.fileName) {
            this._relevantDataPresent = false;
            return {};
        }

        let dataObjectForAA = {};

        if (this.isEventForAdobeAnalytics || window.s) {
            const getQueryParam = this._eventSender.getQueryParamFunction();
            dataObjectForAA = this._eventSender.dataObjectForAA;
            dataObjectForAA["a.dc.utm_medium"] = getQueryParam("utm_medium");
            dataObjectForAA["a.dc.utm_source"] = getQueryParam("utm_source");
            dataObjectForAA["a.dc.utm_campaign"] = getQueryParam("utm_campaign");
            dataObjectForAA["a.dc.utm_term"] = getQueryParam("utm_term");
            dataObjectForAA["a.dc.utm_content"] = getQueryParam("utm_content");
            dataObjectForAA["a.dc.filename"] = eventData.data.fileName;
        }

        const dataObjectForGA = {
            parameters: {
                filename: eventData.data.fileName,
            },
        };

        if (this._eventHandlerMap.has(eventData.type)) {
            // eslint-disable-next-line max-len
            const data = this._eventHandlerMap.get(eventData.type)(dataObjectForAA, dataObjectForGA, eventData);
            Object.assign(dataObjectForAA, data.dataObjectForAA);
            return data;
        }

        this._relevantDataPresent = false;
        return {};
    }

    // Add all parameters to data object required for DOCUMENT OPEN event
    _addAnalyticsDataForDocumentOpen(dataObjectForAA, dataObjectForGA) {
        return {
            dataObjectForAA: { ...dataObjectForAA, "a.action": AnalyticsActions.LOAD_PDF, "a.dc.loadPDF": 1 },
            dataObjectForGA: { ...dataObjectForGA, action: AnalyticsActions.LOAD_PDF },
        };
    }

    // Add all parameters to data object required for PAGE VIEW event
    _addAnalyticsDataForPageView(dataObjectForAA, dataObjectForGA, eventData) {
        return {
            dataObjectForAA: {
                ...dataObjectForAA,
                "a.action": AnalyticsActions.PAGE_VIEW,
                "a.dc.viewPage": 1,
                "a.dc.pageNum": eventData.data.pageNumber,
            },
            dataObjectForGA: {
                ...dataObjectForGA,
                action: AnalyticsActions.PAGE_VIEW,
                parameters: { ...dataObjectForGA.parameters, pageNum: eventData.data.pageNumber },
            },
        };
    }

    // Add all parameters to data object required for DOCUMENT DOWNLOAD event
    _addAnalyticsDataForDocumentDownload(dataObjectForAA, dataObjectForGA) {
        return {
            dataObjectForAA: { ...dataObjectForAA, "a.action": AnalyticsActions.DOWNLOAD, "a.dc.download": 1 },
            dataObjectForGA: { ...dataObjectForGA, action: AnalyticsActions.DOWNLOAD },
        };
    }

    // Add all parameters to data object required for DOCUMENT PRINT event
    _addAnalyticsDataForDocumentPrint(dataObjectForAA, dataObjectForGA) {
        return {
            dataObjectForAA: { ...dataObjectForAA, "a.action": AnalyticsActions.PRINT, "a.dc.print": 1 },
            dataObjectForGA: { ...dataObjectForGA, action: AnalyticsActions.PRINT },
        };
    }

    // Add all parameters to data object required for TEXT SEARCH event
    _addAnalyticsDataForTextSearch(dataObjectForAA, dataObjectForGA, eventData) {
        return {
            dataObjectForAA: {
                ...dataObjectForAA,
                "a.action": AnalyticsActions.SEARCH,
                "a.dc.search": 1,
                "a.dc.searchTerm": eventData.data.searchedText,
            },
            dataObjectForGA: {
                ...dataObjectForGA,
                action: AnalyticsActions.SEARCH,
                parameters: { ...dataObjectForGA.parameters, searchTerm: eventData.data.searchedText },
            },
        };
    }

    // Add all parameters to data object required for BOOKMARK CLICK event
    _addAnalyticsDataForBookmarkClick(dataObjectForAA, dataObjectForGA) {
        return {
            dataObjectForAA: {
                ...dataObjectForAA,
                "a.action": AnalyticsActions.BOOKMARK_CLICK,
                "a.dc.bookmarkClicked": 1,
            },
            dataObjectForGA: { ...dataObjectForGA, action: AnalyticsActions.BOOKMARK_CLICK },
        };
    }

    // Add all parameters to data object required for HYPERLINK OPEN event
    _addAnalyticsDataForHyperlinkOpen(dataObjectForAA, dataObjectForGA) {
        return {
            dataObjectForAA: {
                ...dataObjectForAA,
                "a.action": AnalyticsActions.HYPERLINK_OPEN,
                "a.dc.hyperlinkOpen": 1,
            },
            dataObjectForGA: { ...dataObjectForGA, action: AnalyticsActions.HYPERLINK_OPEN },
        };
    }

    // Add all parameters to data object required for TEXT COPY event
    _addAnalyticsDataForTextCopy(dataObjectForAA, dataObjectForGA) {
        return {
            dataObjectForAA: { ...dataObjectForAA, "a.action": AnalyticsActions.TEXT_COPY, "a.dc.copyText": 1 },
            dataObjectForGA: { ...dataObjectForGA, action: AnalyticsActions.TEXT_COPY },
        };
    }

    // Add all parameters to data object required for ZOOM LEVEL event
    _addAnalyticsDataForZoomLevel(dataObjectForAA, dataObjectForGA, eventData) {
        return {
            dataObjectForAA: {
                ...dataObjectForAA,
                "a.action": AnalyticsActions.CHANGE_ZOOM,
                "a.dc.zoomLevel": eventData.data.zoomLevel,
            },
            dataObjectForGA: {
                ...dataObjectForGA,
                action: AnalyticsActions.CHANGE_ZOOM,
                parameters: { ...dataObjectForGA.parameters, zoomLevel: eventData.data.zoomLevel },
            },
        };
    }

    // Sends event data object
    _sendEvent(data) {
        if (this._relevantDataPresent) {
            this._eventSender.sendEvent(data);
        }
    }

    // Clears event data object
    _clearData() {
        if (this.isEventForAdobeAnalytics || window.s) {
            setTimeout(() => {
                this._eventSender.clearData();
                const dataObjectForAA = this._eventSender.dataObjectForAA;
                delete dataObjectForAA["a.action"];
                delete dataObjectForAA["a.dc.loadPDF"];
                delete dataObjectForAA["a.dc.viewPage"];
                delete dataObjectForAA["a.dc.pageNum"];
                delete dataObjectForAA["a.dc.download"];
                delete dataObjectForAA["a.dc.print"];
                delete dataObjectForAA["a.dc.search"];
                delete dataObjectForAA["a.dc.searchTerm"];
                delete dataObjectForAA["a.dc.hyperlinkOpen"];
                delete dataObjectForAA["a.dc.bookmarkClicked"];
                delete dataObjectForAA["a.dc.copyText"];
                delete dataObjectForAA["a.dc.zoomLevel"];
                delete dataObjectForAA["a.dc.filename"];
            }, CLEAR_DATA_TIMEOUT);
        }
    }
}
