/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import SearchAPICommands from "common/constants/SearchAPICommands";
import { InternalCallbackTypes } from "common/constants/CallbackTypes";

export default class SearchManager {
    constructor(searchId, serviceFactory) {
        this._searchId = searchId;
        this._callbackService = serviceFactory.getService("CallbackService");
        this._commandSenderService = serviceFactory.getService("CommandSenderService");
    }

    executeCommand = command => {
        if (SearchAPICommands[command]) {
            return this._commandSenderService.sendCommand(command, { searchId: this._searchId });
        }
        return Promise.reject({ message: `Search API Command ${command} not supported.` });
    }

    onResultsUpdate = callbackFn => {
        const callbackFnPromise = (...args) => new Promise(resolve => {
            callbackFn.call(null, ...args);
            resolve();
        });
        this._callbackService.registerCallback(InternalCallbackTypes.ON_SEARCH_RESULTS_UPDATE, callbackFnPromise, {});
        return this.executeCommand(SearchAPICommands.ON_RESULTS_UPDATE);
    }

    next = () => this.executeCommand(SearchAPICommands.NEXT);

    previous = () => this.executeCommand(SearchAPICommands.PREVIOUS);

    clear = () => this.executeCommand(SearchAPICommands.CLEAR);

    getExposedAPIs = () => ({
        onResultsUpdate: this.onResultsUpdate,
        next: this.next,
        previous: this.previous,
        clear: this.clear,
    });
}
