/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

const styleInnerHTMLs = [
    `.ViewSDK_hideOverflow {
        overflow: hidden;
    }`,
    `.ViewSDK_parentRelativeWidth {
        width: 100%;
    }`,
    `.ViewSDK_viewportRelativeWidth {
        width: 100vw;
    }`,
    `.ViewSDK_parentRelativeHeight {
        height: 100%;
    }`,
    `.ViewSDK_viewportRelativeHeight {
        height: 100vh;
    }`,
    `.ViewSDK_fullScreenPDFViewer {
        width:100vw !important; height:100vh !important;
        max-width:100vw !important; max-height:100vh !important;
        position:fixed; top:0; left:0; z-index:9999;
        margin: 0 !important; padding: 0 !important;
        border: none !important;
    }`,
    `.ViewSDK_LBFullScreenPDFViewer {
        width:100vw !important; height:100vh !important;
        max-width:100vw !important; max-height:100vh !important;
        position:fixed; top:0; left:0; z-index:10000;
        margin: 0 !important; padding: 0 !important;
        background-color: rgba(0, 0, 0, 0.2);
        border: none !important;
    }`,
    ":root { --ViewSDK-mobile-viewport-height:1vh; }",
    `.ViewSDK_fullScreenPDFViewerMobile {
        width:100vw !important; height:calc(100 * var(--ViewSDK-mobile-viewport-height)) !important;
        max-width:100vw !important; max-height:calc(100 * var(--ViewSDK-mobile-viewport-height)) !important;
        position:fixed; top:0; left:0; z-index:9999;
        margin: 0 !important; padding: 0 !important;
        border: none !important;
    }`,
    `.ViewSDK_LBLoader {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 10000;
        border: 4px solid #f3f3f3;
        border-top: 4px solid #1473E6;
        width: 56px !important;
        height: 56px !important;
        margin: -32px 0 0 -32px;
        border-radius: 50%;
        -webkit-animation: lbSpin 2s linear infinite;
        animation: lbSpin 2s linear infinite;
    }
    @-webkit-keyframes lbSpin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes lbSpin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }`,
];

const createStyles = () => {
    const doc = window.document;
    let style;
    styleInnerHTMLs.forEach(innerHTML => {
        style = doc.createElement("style");
        style.type = "text/css";
        style.innerHTML = innerHTML;
        doc.getElementsByTagName("head")[0].appendChild(style);
    });
};

const addStyleClass = (element, styleClass) => {
    element.classList.add(styleClass);
};

const removeStyleClass = (element, styleClass) => {
    element.classList.remove(styleClass);
};

const cleanUpElement = element => {
    removeStyleClass(element, "ViewSDK_hideOverflow");
    removeStyleClass(element, "ViewSDK_parentRelativeWidth");
    removeStyleClass(element, "ViewSDK_viewportRelativeWidth");
    removeStyleClass(element, "ViewSDK_parentRelativeHeight");
    removeStyleClass(element, "ViewSDK_viewportRelativeHeight");
    removeStyleClass(element, "ViewSDK_fullScreenPDFViewer");
    removeStyleClass(element, "ViewSDK_fullScreenPDFViewerMobile");
};

const addWidthToElement = element => {
    const parentNodeName = element.parentNode ? element.parentNode.nodeName.toLowerCase() : "";
    if (parentNodeName === "body") {
        addStyleClass(element, "ViewSDK_viewportRelativeWidth");
    } else {
        addStyleClass(element, "ViewSDK_parentRelativeWidth");
    }
};

const addHeightToElement = element => {
    const parentNodeName = element.parentNode ? element.parentNode.nodeName.toLowerCase() : "";
    if (parentNodeName === "body") {
        addStyleClass(element, "ViewSDK_viewportRelativeHeight");
    } else {
        addStyleClass(element, "ViewSDK_parentRelativeHeight");
    }
};

const blockOverflow = element => {
    addStyleClass(element, "ViewSDK_hideOverflow");
};

const allowOverflow = element => {
    removeStyleClass(element, "ViewSDK_hideOverflow");
};

const addFullScreenToElement = (element, isIOS) => {
    if (isIOS) {
        addStyleClass(element, "ViewSDK_fullScreenPDFViewerMobile");
    } else {
        addStyleClass(element, "ViewSDK_fullScreenPDFViewer");
    }
};

const addLBFullScreenToElement = element => {
    addStyleClass(element, "ViewSDK_LBFullScreenPDFViewer");
};

const removeFullScreenFromElement = (element, isIOS) => {
    if (isIOS) {
        removeStyleClass(element, "ViewSDK_fullScreenPDFViewerMobile");
    } else {
        removeStyleClass(element, "ViewSDK_fullScreenPDFViewer");
    }
};

export {
    createStyles, cleanUpElement, addWidthToElement, addHeightToElement, blockOverflow, allowOverflow,
    addLBFullScreenToElement, addFullScreenToElement, removeFullScreenFromElement,
};
