/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import "whatwg-fetch"; // it will automatically polyfill window.fetch and related APIs:
import "promise-polyfill/src/polyfill";
import "bundle/util/Polyfill";
import ViewSDKInterfaceAppBase from "bundle/interface/ViewSDKInterfaceApp";
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";
import getWindowStartTime from "bundle/util/TimeUtil";
import { createStyles } from "interface/util/StylesUtil";
import { getUrlWithoutSearchParams } from "bundle/util/UriUtils";
import getFinalConfig from "interface/util/ConfigUtil";
import { getLoadedScript } from "interface/util/ScriptLoader";

const hostAppStartTime = getWindowStartTime();
createStyles();

export default class ViewSDKInterfaceApp extends ViewSDKInterfaceAppBase {
    constructor(config, serviceFactory) {
        super(config, serviceFactory);
        this._initMessaging();
        this._initPDFAnalytics();
        this._embedModeHandlerService = this._serviceFactory.getService("EmbedModeHandlerService");
        this._adobeViewer = this._serviceFactory.getService("AdobeViewer");
    }

    /**
    * @method
    * @description
    * previewFile(fileInfo, previewConfig) method to preview/embed pdf file.
    *
    * @param {object} fileInfo - file information to preview.
    * @param {object} previewConfig - config to control the UI of file preview.
    * @return {Promise} - promise that resolves to PreviewRef when PDF is rendered.
    */
    previewFile(fileInfo, previewConfig) {
        this._logExternal("Starting process to preview file.", fileInfo.metaData, previewConfig);
        return this._embedModeHandlerService
            .initialiseAction(fileInfo, previewConfig, IFrameMessageTypes.PREVIEW, hostAppStartTime)
            .then(() => this._adobeViewer.getExposedAPIs({ previewConfig }));
    }

    /**
     * @method
     * @description
     * method associated with document loading in the browser
     * @param {Number} loaded - percentage of file loading
     * @param {Number} total - total file size
     */
    fileProgressLoadingData(loaded, total) {
        this._embedModeHandlerService.fileProgressLoadingData(loaded, total);
    }

    _initMessaging() {
        const messagingService = this._serviceFactory.getService("MessagingService");
        messagingService.setSessionId(this._config.msi);
        messagingService.setDOMEventListenerService(this._serviceFactory.getService("DOMEventListenerService"));
        const messageSender = this._serviceFactory.getService("MessageSender");
        messageSender.initialize(this._config, messagingService);
    }

    _initServices() {
        const messagingService = this._serviceFactory.getService("MessagingService");
        this._serviceFactory.getService("FullScreenMessageHandler").initialize(this._config.divId, messagingService);
        this._serviceFactory.getService("EmbedModeHandlerService").initialize(this._config, this._serviceFactory);
    }

    _initConfig(config) {
        super._initConfig(config);
        this._config.windowHref = getUrlWithoutSearchParams(window.location.href);
        this._config.loadedScript = getLoadedScript();
    }

    _initPDFAnalytics() {
        if (this._config.sendAutoPDFAnalytics) {
            const eventSender = this._serviceFactory.getService("AnalyticsSenderService");
            eventSender.initialize(this._config);
            const feederService = this._serviceFactory.getService("PDFAnalyticsFeederService");
            const messagingService = this._serviceFactory.getService("MessagingService");
            feederService.initialize(messagingService, eventSender, this._config);
        }
    }

    _getFinalConfig(config) {
        return getFinalConfig(config);
    }
}
