/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2019 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

const IFrameMessageTypes = {
    INIT: "init",
    READY: "ready",
    PREVIEW: "preview",
    PROMISE_MESSAGE: "promise_message",
    EVENT: "event",
    CALLBACK: "callback",
    EXECUTE_COMMAND: "execute_command",
    COMMAND_RESULT: "command_result",
    LICENSE_CALL_INIT: "license_call_init",
    LICENSE_CALL_RESULT: "license_call_result",
    FILE_LOADING_PROGRESS_DATA: "file_loading_progress_data",
    FULL_SCREEN_TOGGLE: "full_screen_toggle",
    HEIGHT_IN_VIEW: "height_in_view",
    UPDATE_HEIGHT: "update_height",
    LB_OPEN_PDF_VIEWER: "lb_open_pdf_viewer",
    LB_EXIT_PDF_VIEWER: "lb_exit_pdf_viewer",
    CUSTOM_ICON_CLICK: "custom_icon_click",
};

export default IFrameMessageTypes;
