/* eslint no-undef: 0 */
/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/**
 * @file
 */
import loadExtras from './loadExtras';
import { getSingletonFunction } from '../core/ProviderUtil';

/**
  * @classdesc
  * Target Provider to manage target implemenation
  * @class
  */
class TargetAPIProvider {
  /**
    * @constructor
    * @param {Object} obj
    * @param {ArrayOf(Object)}  obj.mboxes - Array of objects with the shape {name: String}
    * @param {Object}  obj.defaultParameters - Object of key value pairs, example: {'data-language': locale2.getLocale()},
    * @param {Boolean} obj.imsEnabled - pass a flag to indicate whether ims is enabled. enabled by default
    */

  constructor(obj = {}) {
    this.loadPromise = loadExtras().then(() => {
      this.TargetImplementation = window.adobe_dc_sdk.TargetImplementation;
      const targetAPIImpl = new this.TargetImplementation(obj);
      return targetAPIImpl.ready();
    });
  }

  /**
    * @description
    * Standard provider ready() method to allow lazy instantiation of API.
    * @method
    * @returns {Promise} - promise that resolves when TargetProvider provider has been instantiated
    */
  ready() {
    return this.loadPromise;
  }
}
// This allows for providers.x().then() to be called before providers.x(config).
TargetAPIProvider.getInstance = getSingletonFunction(TargetAPIProvider);
export default TargetAPIProvider;
