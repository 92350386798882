/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/**
 * @file Limits Provider for signedIn Acrobat.
 */

/* global window */
import EventEmitter from 'eventemitter3';
import { getSingletonFunction } from '../core/ProviderUtil';
// eslint-disable-next-line import/no-cycle
import { providers } from './Providers';
import DcapiAPI from './DcapiAPI';
import { auth2 } from './Auth2API';

const LIMITS_EXPIRY = 'limits2-expiry';
const PAC_DISABLED_ACTIONS = [
  'fillsign',
  'sendforsignature',
  'share-link',
  'upsell',
  'upgrade',
  'start-free-trial',
  'connectors',
  'marketing-integration',
  'sophia-integration',
  'complete-account',
];
let listener;

// debug/test info for QE
const limitsInfo = limits => {
  const serverIdleTime = Date.now() - limits.serverActivityTime;
  const timeLeft = Math.max(limits.serverTrustTime - serverIdleTime, 0);
  const floor = n => Math.floor(n * 100.0) / 100;
  return {
    limits: limits.limits,
    timeToExpiry: `${(floor((limits.getLocalExpiry() - Date.now()) / (1000.0 * 60 * 60)))} hours`,
    timeToTrustServer: `${floor(timeLeft / (1000.0 * 60))} minutes`,
  };
};

class Limits2Provider {
  static getInstance = getSingletonFunction(Limits2Provider);

  serverTrustTime = 5 * 60 * 1000;

  userLimitsDebounce = 5000;

  pacDisabledActions = PAC_DISABLED_ACTIONS;

  syncServerLimits = async useCache => {
    if (!useCache) {
      this.userProvider.clear();
    }
    const newLimits = await this.userProvider.getLimitsVerbs();
    const filteredLimits = {};
    this.staticLimits = {};
    Object.keys(newLimits).forEach(limit => {
      if (newLimits[limit] && newLimits[limit].limits !== undefined) {
        filteredLimits[limit] = newLimits[limit].limits;
      }
      if (newLimits[limit] && newLimits[limit].configuration !== undefined) {
        this.staticLimits[limit] = newLimits[limit].configuration;
      }
    });
    const serverIdleTime = Date.now() - this.serverActivityTime;
    // trust the server after 5 minutes
    const trustServerLimits = serverIdleTime > this.serverTrustTime;
    // returns a boolean to indicate whether the limits changed
    return this.mergeLimits(filteredLimits, trustServerLimits);
  }

  expiryTimer = null;

  limits = {};

  staticLimits = {};

  _emitter = new EventEmitter();

  userProvider = null;

  get emitter() {
    return this._emitter;
  }

  /**
   * monitor dcapi activity, and optimistically decrement our local limits accordingly
   * This allows our limits to be immediately accurate instead of wating for server
   * eventual consistency.
   */
  listener = (operation, params) => {
    const { headers = {} } = params;
    const verb = headers['x-user-action-name'];
    let changed = false;

    const decrementRemaining = (limits, key) => {
      if (limits[key] && limits[key].remaining > 0) {
        limits[key].remaining -= 1;
        if (limits.uber && limits.uber.remaining > 0) {
          limits.uber.remaining -= 1;
        }
        changed = true;
      }
    };

    switch (operation) {
    case 'assets.createpdf':
    case 'assets.pdf_actions':
    case 'assets.exportpdf':
    case 'assets.splitpdf':
      decrementRemaining(this.limits, verb);
      break;
    }
    /**
     * Keep track of the last server operation.
     * After a period of time for eventual consistency to settle down, we will trust it again.
     * Dont count operations on users.
     */
    if (!operation.startsWith('user')) {
      this.serverActivityTime = Date.now();
    }
    if (changed) {
      this.publish();
    }
  }

  ready = async () => {
    this.userProvider = await providers.user();

    // load limits without clearing them first
    // first time in, we can trust that they're fresh
    await this.loadLimits(true);
    this.scheduleExpiry();
    this.listener = this.listener.bind(this);
    DcapiAPI.getInstance().then(api => {
      const dcapi = api.getDcapi();
      dcapi.addListener(this.listener);
    });
    this.serverActivityTime = Date.now();
    if (window.adobe_dc_sdk) {
      window.adobe_dc_sdk.limits2Info = () => limitsInfo(this);
    }
    return this;
  }

  terminate() {
    DcapiAPI.getInstance().then(api => {
      const dcapi = api.getDcapi();
      dcapi.removeListener(listener);
    });
  }

  cancelExpiry = () => this.expiryTimer && clearTimeout(this.expiryTimer);

  setExpiry = (ms = 24 * 60 * 60 * 1000) => {
    this.setLocalInt(LIMITS_EXPIRY, Date.now() + ms);
  }

  getLocalExpiry = () => {
    let localStorageValue = null;
    try {
      localStorageValue = window.localStorage.getItem(LIMITS_EXPIRY);
    } catch (e) {
      // ignore
    }
    let localVal = null;
    if (localStorageValue !== null) {
      localVal = parseInt(localStorageValue, 10);
    }
    return localVal;
  };

  scheduleExpiry = reset => {
    let expiry = this.getLocalExpiry();
    if (expiry === null || reset) {
      // First time user
      this.setExpiry();
      expiry = this.getLocalExpiry();
    }
    const now = Date.now();
    const howLongToWait = expiry - now;
    this.cancelExpiry();
    if (howLongToWait > 0) {
      this.expiryTimer = setTimeout(
        this.onLimitsExpired,
        howLongToWait,
      );
    }
  }

  onLimitsExpired = async () => {
    await this.reset();
    this.scheduleExpiry(true);
  }

  subscribe = subscriber => {
    this.emitter.addListener('change', subscriber);
  }

  unsubscribe = subscriber => {
    this.emitter.removeListener('change', subscriber);
  }

  limitsExpired = () => {
    const localExpiry = this.getLocalExpiry();
    if (localExpiry === null) {
      return false;
    }
    return (Date.now() > localExpiry);
  };

  loadLimitsTimeout;

  /**
   * this async method cleares the user provider to cause
   * a fresh `user/self` to be retrieved, and then
   * awaits the limits key of the `self` response.
   * This method is debounced so that we don't call it multiple times at startup.
   * Once every five seconds...
   */
  loadLimits = async useCache => {
    const later = () => {
      this.loadLimitsTimeout = null;
    };
    const callNow = !this.loadLimitsTimeout;
    if (callNow) {
      this.loadLimitsTimeout = setTimeout(later, this.userLimitsDebounce);
      const serverChanged = await this.syncServerLimits(useCache);
      if (serverChanged) {
        // today, any change in server limits needs to trigger a re-set
        // on our expiry time.  In the future, we need to do this based on
        // actual times returned by the server
        if (this.limitsExpired()) this.scheduleExpiry(true);
        this.publish();
      }
    }
    return this.limits;
  }

  mergeLimits(newLimits, trustNewLimits) {
    // If the server limits are lower, use them.
    // If they're not lower, then they're probably not current
    // (unless 24 hours has expired)
    // use the trustNewLimits parameter to determine if the input limits are current
    let changed = false;
    const isLower = (newLimit, currentLimit) => {
      if (!newLimit) {
        return false;
      }
      if (!currentLimit) {
        return true;
      }
      return newLimit.remaining < currentLimit.remaining;
    };

    const isDifferent = (newLimit, currentLimit) => {
      if (!newLimit) {
        return false;
      }
      if (!currentLimit) {
        return true;
      }
      return newLimit.remaining !== currentLimit.remaining;
    };

    Object.keys(newLimits).forEach(limit => {
      if (isLower(newLimits[limit], this.limits[limit])
        || (trustNewLimits && isDifferent(newLimits[limit], this.limits[limit]))) {
        this.limits[limit] = newLimits[limit];
        changed = true;
      }
    });
    return changed;
  }

  getExhaustedLimits = () => Object.keys(this.limits).filter(limit => this.limits[limit].remaining === 0);

  getUsedLimits = () => {
    // this is not implemented for signed in users yet
    throw new Error('Not implemented yet');
  }

  getStaticLimits = () => ({ ...this.staticLimits });

  getLimit = limitName => this.limits[limitName] || {};

  canPerform = verb => {
    if (this.isPrivilegedAction(verb)) {
      return false;
    }
    const limit = this.getLimit(verb);
    if (limit.remaining === -1) {
      return true;
    }
    const uberLimit = this.getLimit('uber');
    if (uberLimit.remaining === 0 || limit.remaining === 0) {
      return false;
    }
    return true;
  }

  reset = async () => {
    this.limits = {};
    clearTimeout(this.loadLimitsTimeout);
    this.loadLimitsTimeout = null;
    await this.loadLimits();
  }

  setLocalInt = (item, intValue) => {
    try {
      window.localStorage.setItem(item, intValue.toString());
    } catch (e) {
      // ignore
    }
  }

  /**
   * @description
   * Set disabled actions for PAC and default PAC_DISABLED_ACTIONS list is ignored.
   * @method
   * @param {Array<string>} disabledActions Actions disabled for pac
   * @returns {undefined}
   */
  setDisabledActionsForPAC = (disabledActions = PAC_DISABLED_ACTIONS) => {
    this.pacDisabledActions = [...disabledActions];
  };

  /**
   * @description
   * Checks whether the action is privileged action or not.
   * @method
   * @param {string} action Action name
   * @returns {boolean} Flag indicating whether the operation is privileged or not.
   */
  isPrivilegedAction = action => {
    if (auth2.isIncompleteAccount()) {
      return this.pacDisabledActions.includes(action);
    }
    return false;
  }

  publish = () => {
    this.emitter.emit('change', {
      nextExhaustedLimits: [...this.getExhaustedLimits()],
      nextLimits: { ...this.limits },
      nextUsedLimits: undefined, // TODO: replace with [...this.getUsedLimits()] once implemented
    });
  }
}
export default Limits2Provider;
