/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

export default class DOMEventListenerService {
    constructor() {
        this._eventListeners = [];
    }

    initialize(messageSender) {
        this._messageSender = messageSender;
        this._startInterval();
    }

    _startInterval() {
        if (!this._intervalId && this._messageSender && this._eventListeners.length > 0) {
            this._intervalId = setInterval(() => {
                if (!this._messageSender.isInstanceStillAlive()) {
                    this._cleanUp();
                }
            }, 100);
        }
    }

    listen(element, on, cb) {
        this._eventListeners.push({
            element,
            on,
            cb,
        });
        element.addEventListener(on, cb);
        this._startInterval();
    }

    _cleanUp() {
        this._eventListeners.forEach(item => {
            item.element.removeEventListener(item.on, item.cb);
        });
        this._eventListeners = [];
        clearInterval(this._intervalId);
    }
}
