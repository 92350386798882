/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

const ANALYTICS_OBJECT_INIT_TIMEOUT = 15000;
const ANALYTICS_OBJECT_PRESENCE_RECHECK_TIMEOUT = 200;
const HANDLE_EVENT_TIMEOUT = 600;
const CLEAR_DATA_TIMEOUT = 150;

export {
    ANALYTICS_OBJECT_INIT_TIMEOUT,
    ANALYTICS_OBJECT_PRESENCE_RECHECK_TIMEOUT,
    HANDLE_EVENT_TIMEOUT,
    CLEAR_DATA_TIMEOUT,
};
