/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* eslint-disable no-underscore-dangle */
/* global window */

import uuidv4 from 'uuid/v4';
import Deferred from './Deferred';

const supportedOrigin = origin => {
  const validOriginList = [
    // any of our test or prod chrome extensions
    'efaidnbmnnnibpcajpcglclefindmkaj',
    'dagpahdciccokkcaioeejhkippljgcci',
    'iagfemhobomkehjbnkfipenmdancdjff',
    'djadjknidgpacfohfhmimdifiggegoci',
    'memepcobodlebmohdlfnjiaalggfcpic',
    'elhekieabhbkpmcefcoobjddigjcaadp',
    'hgednelcgbmkdebjhejlmbgigmkcbemg',
    'koknlmjfmkeaclpcgkfecddjfkoffgbo',
  ];
  return !!validOriginList.find(id => id === origin);
};

/**
 * {DcExtensionStorage} Provides a mechanism to talk to DC Extension.
 * The communication messages passed back and forth are outline in
 * https://git.corp.adobe.com/rite/3rd-party-cookies-poc
 */
export default class DcExtensionStorage {
  constructor() {
    this.cache = {};
    this.extensionId = null;
    this.timeOutMs = 10 * 1000; // 10 sec
    this.waitForExtension = new Deferred();
  }

  /**
   * Get the values of given keys
   *
   * @param {Array<string>} keys - the keys you want to retrieve the values for
   * @param {boolean} ignoreCache - Ignore values in cache and get the value from the extension directly
   *
   * @returns {Promise<Object.<string,string>>} A promise that resolves with an object containing the key/value pair
   *                                            of the requested keys.
   */
  getItems = (keys = [], { ignoreCache = false } = {}) => {
    const cachedValues = {};
    let areAllKeysCached = true;
    keys.forEach(key => {
      if (this.cache[key] !== undefined) {
        cachedValues[key] = this.cache[key];
      } else {
        areAllKeysCached = false;
      }
    });

    if (areAllKeysCached && !ignoreCache) {
      return Promise.resolve(cachedValues);
    }

    const extensionRequest = {
      keys,
      task_uid: uuidv4(),
      task: 'storage_bulk_read',
      type: 'WebRequest',
    };

    return this.__messageExtension(extensionRequest).then(result => {
      const values = result.values || {};
      Object.keys(values).forEach(key => {
        this.cache[key] = values[key];
      });
      return values;
    });
  }

  /**
   * Set the item values
   *
   * @param {Object.<string,string>} values - an array of key/value pair of values to set
   *
   * @returns {Promise<{boolean}>} A promise that resolves with true if the operation was successful, false otherwise
  */
  setItems = (values = {}) => {
    Object.keys(values).forEach(key => {
      this.cache[key] = values[key];
    });

    const extensionRequest = {
      data: values,
      task_uid: uuidv4(),
      task: 'storage_bulk_write',
      type: 'WebRequest',
    };

    return this.__messageExtension(extensionRequest).then(result => result.status === 200);
  }

  /**
   * Remove the items with the given keys
   *
   * @param {Array<string>} keys - the keys of the items to remove
   *
   * @returns {Promise<{boolean}>} A promise that resolves with true if the operation was successful, false otherwise
   */
  removeItems = (keys = []) => {
    keys.forEach(key => {
      delete this.cache[key];
    });

    const extensionRequest = {
      keys,
      task_uid: uuidv4(),
      task: 'storage_bulk_remove',
      type: 'WebRequest',
    };

    return this.__messageExtension(extensionRequest).then(result => result.status === 200);
  }

  /**
   * Sets configuration required to talk to the extension
   *
   * @param {string} extensionId - the id of the extension to talk to
   * @param {Object.<string,string>} initialData - the initial data to set in the cache
   *
   * @returns undefined
  */
  configure = ({ extensionId, initialData = {} }) => {
    if (!supportedOrigin(extensionId)) {
      this.waitForExtension.reject(new Error(`Unsupported extension id: ${extensionId}`));
      return;
    }
    this.extensionId = extensionId;
    this.cache = { ...this.cache, ...initialData };
    this.waitForExtension.resolve(extensionId);
  }

  /**
   * Check if the given extension id is a supported extension
   *
   * @param {string} id - the id of the extension
   *
   * @returns {Boolean} - Returns true if the extension id is on the list of allowed extensions
   *                      false otherwise
  */
  isSupportedExtension = origin => supportedOrigin(origin);

  /**
   * Determines if the users has Acrobat or Reader installed
   *
   * @returns {Promise<{boolean}>} A promise that resolves with true
   *    if the user has Acrobat or Reader installed, false otherwise.
   *    Promise rejects if the user does not have chrome extension installed
   *    or there's a timeout
   */
  isAcrobatOrReaderInstalled = () => {
    if (this.requestPromise) {
      return this.requestPromise;
    }

    const extensionRequest = {
      type: 'WebRequest',
      task: 'detect_desktop',
    };

    this.requestPromise = this.__messageExtension(extensionRequest)
      .then(response => {
        const { status, result, code } = response;
        // result holds => NoApp, Reader, Acrobat
        if (status === 'success') {
          return result !== 'NoApp';
        }
        return Promise.reject(new Error(code));
      });

    return this.requestPromise;
  }

  /**
   * Get the extension's id
   *
   * @returns {Promise<string>} - A promise that resolves with the extension Id once it's been set
   * @private
  */
  __getExtensionId = () => {
    if (this.extensionId) {
      return Promise.resolve(this.extensionId);
    }
    return this.waitForExtension.promise;
  }

  /**
   * Messages the extension to process the request
   * @param request - the request as defined in
   *                  https://git.corp.adobe.com/rite/3rd-party-cookies-poc
   * @returns {Promise} returns a promise that resolves when the request completes or times out
   * @private
  */
  __messageExtension = request => new Promise((resolve, reject) => {
    const timeOutRef = setTimeout(() => {
      reject(new Error('Extension request timed out'));
    }, this.timeOutMs);

    this.__getExtensionId().then(extensionId => {
      try {
        if (window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage) {
          window.chrome.runtime.sendMessage(extensionId, request, response => {
            clearTimeout(timeOutRef);
            if (response) {
              resolve(response);
            } else {
              reject(new Error('Received empty response from Extension'));
            }
          });
        } else {
          reject(new Error('sendMessage API not available'));
        }
      } catch (e) {
        reject(e);
      }
    }).catch(reject);
  });
}

if (!window.adobe_dc_sdk) {
  window.adobe_dc_sdk = {};
}
if (!window.adobe_dc_sdk.util) {
  window.adobe_dc_sdk.util = {};
}
window.adobe_dc_sdk.util.dcExtensionStorage = new DcExtensionStorage();
