/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* global window, localStorage, fetch */
/**
 * @file
 */
/* eslint max-classes-per-file: ["error", 2] */

import uuidv4 from 'uuid/v4';
// eslint-disable-next-line import/no-cycle
import { providers } from './Providers';
import { auth2 } from './Auth2API';
import { getEnvVar, getHostEnv } from '../core/EnvUtil';
import { logging } from './LoggingAPI';
import { router } from './RouterAPI';
import { getSingletonFunction } from '../core/ProviderUtil';

const logger = logging.getLogger('FloodgateProvider');

const DEFAULT_API_KEY = 'dc-local-virgoweb';
const IMS_CLIENT_ID_KEY = 'ims_client_id';
const FLOODGATE_URI_KEY = 'floodgate_uri';
let featureFlags = [];
let metaData = {};

const getClientId = () => getEnvVar(IMS_CLIENT_ID_KEY);

export class TimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = 'TimeoutError';
  }
}

let floodgateImpl;
function getFeatureFlags() {
  return floodgateImpl ? floodgateImpl.getFeatureFlags() : [];
}

function getMetaData() {
  return floodgateImpl ? floodgateImpl.getMetaData() : {};
}

const getFromSessionStorage = key => {
  try {
    if (window.sessionStorage) {
      return window.sessionStorage.getItem(key) || '';
    }
  } catch (e) {
    return '';
  }
};

/**
 * @classdesc
 * Floodgate Provider to manage feature flags
 * @class
 */
class FloodgateProviderImpl {
  /**
   * @constructor
   * @param {Object} obj
   * @param {AuthAPI} obj.auth - The instance of AuthAPI for testing.
   * @param {String} obj.floodgateUri - uri to make floodgate API calls
   * @param {String} obj.imsClientId - ims client id registered with floodgate
   * @param {String} obj.imsToken - The instance of AuthAPI for testing.
   * @param {String} obj.useAnonymousUUID - Use userUUID to fetch floodgate flags for anonymous users.
   * @param {Object} obj.context - Key/value pairs to be passed as context to floodgate
   * @param {Boolean} obj.meta - boolean passed to determine if the api should return meta data.
   * @param {Promise} obj.floodgateResponse - Promise that resolves to floodgate response.
   * Default value is false.
   */
  constructor(obj = {}) {
    try {
      this.auth = obj.auth || auth2;
      this.clientId = obj.imsClientId || getClientId();
      this.context = obj.context || {};
      this.enableMeta = obj.meta || false;
      this.uri = this.getApiUrl(obj.floodgateUri);
      this.useAnonymousUUID = obj.useAnonymousUUID ?? true;
      this.accessToken = this.getAccessToken(obj);
      this.timeoutMS = 5 * 1000;
      this.auth.signInPromise.then(() => {
        // Invalidate ready. Any calls to this provider from this point on will wait for current floodgate data.
        this.readyPromise = null;

        // Get the latest token
        const newAccessToken = this.getAccessToken(obj);

        logger.emit('Floodgate update', {
          level: 'debug',
          signed_in: auth2.isSignedIn,
          old_auth: this.accessToken && this.accessToken.slice(0, 20),
          new_auth: newAccessToken.slice(0, 20),
        });

        this.accessToken = newAccessToken;

        // Reset providers.floodgate entry so that they can get new readyPromise
        providers.floodgate = () => this.ready();
      });
      // if client has provided floodgate api response; use that
      this.clientFloodgateResponse = obj.floodgateResponse || undefined;
      floodgateImpl = this;
    } catch (e) {
      if (this.clientId) {
        logger.warn('FloodgateProviderImpl constuctor', e);
      }

      // Invalidate ready. Any calls to this provider from this point on will wait for current floodgate data.
      this.readyPromise = null;

      // Reset providers.floodgate entry so that they can get new readyPromise
      providers.floodgate = () => this.ready();
    }
  }

  /**
   * @description
   * fetch a copy of feature flags.
   * @method
   * @returns {Array} - the array of feature flags
   */
  getFeatureFlags() {
    // return a copy so that our internals are safe
    return featureFlags.slice();
  }

  getAccessToken = obj => obj.imsToken
  || (this.auth.getAccessTokenInfo() || {}).access_token;

  /**
   * @description
   * Standard provider ready() method to allow lazy instantiation of API.
   * @method
   * @returns {Promise} - promise that resolves when FloodgateProvider provider has been instantiated
   */
  ready() {
    if (!this.readyPromise) {
      this.readyPromise = this.callFloodgateAPI()
        .then(result => {
          this.getArrayOfFlags(result);
          this.getMetaData(result);
          return this;
        });
    }
    return this.readyPromise;
  }

  /**
   * @description
   * Standard provider refreshReady() method to allow lazy instantiation of API.
   * @method
   * @returns {Promise} - promise that resolves when FloodgateProvider provider has been instantiated
   */
  refreshReady() {
    if (!this.refreshReadyPromise) {
      this.refreshReadyPromise = this.callFloodgateAPI()
        .then(result => {
          this.getArrayOfFlags(result);
          this.getMetaData(result);
          return this;
        });
    }
    return this.refreshReadyPromise;
  }

  /**
   * @method
   * @param {String} baseUri - baseUri for the floodgate API passed from the constructor
   * @returns {String} - Floodgate API uri
   * @public
   */
  getApiUrl(baseUri) {
    const clientId = this.clientId;
    const uri = baseUri || getEnvVar(FLOODGATE_URI_KEY);
    if (!uri || !clientId) {
      throw new Error(`missing ${IMS_CLIENT_ID_KEY} or ${FLOODGATE_URI_KEY}`);
    }

    let contextParams = '';
    Object.keys(this.context).forEach(key => {
      contextParams = `${contextParams}&${key}=${this.context[key]}`;
    });

    return `${uri}/v3/feature?clientId=${clientId}&meta=${this.enableMeta}${contextParams}`;
  }

  /**
   * @method
   * @returns {string} - API key
   * @public
   */
  get apiKey() {
    return this.clientId || DEFAULT_API_KEY;
  }

  /**
   * @method
   * @param {object} result - Object containing various relase featureFlags
   * @returns {object} featureFlags - List of feature flafs for each release
   * @public
   */
  getArrayOfFlags(result) {
    if (result) {
      featureFlags = [];
      result.releases.forEach(release => {
        release.features.forEach(flag => featureFlags.push(flag));
      });

      const addFlags = getFromSessionStorage('floodgate-add').split(/[, ]+/);
      addFlags.forEach(flag => {
        if (!featureFlags.includes(flag)) {
          featureFlags.push(flag);
        }
      });

      const removeFlags = getFromSessionStorage('floodgate-remove').split(/[, ]+/);
      featureFlags = featureFlags.filter(flag => !removeFlags.includes(flag));
    }
    // return a copy of the feature flags
    return featureFlags.slice();
  }

  /**
   * @method
   * @param {object} result - Object containing various relase featureFlags
   * @returns {object} FeatureFlag metadata
   * @public
   */
  getMetaData(result) {
    if (result && this.enableMeta) {
      metaData = {};
      result.releases.forEach(release => {
        // eslint-disable-next-line no-unused-expressions
        release.meta?.forEach(({ k, v }) => {
          try {
            metaData[k] = window.atob(v);
          } catch (error) {
            logger.error('base64 decryption error:', error);
          }
        });
      });
      try {
        const metaFromSession = getFromSessionStorage('floodgate-meta');
        if (metaFromSession) {
          Object.entries(JSON.parse(metaFromSession)).forEach(([k, v]) => {
            metaData[k] = v;
          });
        }
      } catch (e) {
        logger.warn('error occurred parsing json. error', e);
      }
    }

    return { ...metaData };
  }

  /**
   * @method
   * @param {string} flagName - The flag name whose presence is to be checked.
   * @returns {boolean} - Result of the find operation for given flag
   * @public
   */
  hasFlag(flagName) {
    return featureFlags.includes(flagName);
  }

  /**
   *
   * @param {string} flagName - The flag name whose presence is to be checked.
   * @param {boolean} signedInCheck - The signed in flag boolean.
   * @returns {boolean} - Result a promise with a value of true/false.
   */
  hasFlagAsync = async (flagName, signedInCheck = true) => {
    if (signedInCheck) {
      await this.isImsReadySet();
      await this.refreshReady();
    }
    return featureFlags.includes(flagName);
  }

  /**
   * @returns {boolean} - Result is a value true/false
   */
  isImsReadySet() {
    return window.adobeIMS && window.adobe_dc_sdk.loaded && window.adobe_dc_sdk.loaded.imsReady;
  }

  createAnonUserUUID() {
    const id = `${getHostEnv()}_${this.clientId}_${uuidv4()}`;
    try {
      localStorage.setItem('anonUserUUID', id);
    } catch (e) {
      // squelch if local storage is not available
      logger.warn('Cannot access localStorage', e);
    }
    return id;
  }

  /**
   * @method
   * @public
   */
  callFloodgateAPI() {
    if (this.clientFloodgateResponse) {
      return Promise.resolve(this.clientFloodgateResponse);
    }
    const authentication = this.accessToken ? `Bearer ${this.accessToken}` : '';

    // Ensure we do not call floodgate again unnecessary
    // Use appropriate promise as per authentication status
    if (authentication && this.floodgateAPIWithAuthPromise) {
      return this.floodgateAPIWithAuthPromise;
    }
    if (!authentication && this.floodgateAPIPromise) {
      return this.floodgateAPIPromise;
    }
    const fetchHeaders = {
      'x-api-key': this.apiKey,
    };
      // Always use the uuid for ensuring stickiness in case of unath, or unath -> auth scenario
    if (this.useAnonymousUUID) {
      const queryParams = router.getQueryParams();
      // The router will have query param when coming from other origins like acrobat.com
      try {
        fetchHeaders['x-adobe-uuid'] = queryParams.anonUserUUID || localStorage.getItem('anonUserUUID');
      } catch (e) {
        // squelch if local storage is not available
        logger.warn('Cannot access localStorage', e);
      }
      if (!fetchHeaders['x-adobe-uuid']) {
        fetchHeaders['x-adobe-uuid'] = this.createAnonUserUUID();
      }
    }

    if (authentication) {
      fetchHeaders.Authorization = authentication;
    }

    const fetchPromise = fetch(this.uri, {
      method: 'GET',
      headers: fetchHeaders,
    });

    let timeoutId;
    const fetchTimeout = new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => reject(new TimeoutError(`request timed out after ${this.timeoutMS} ms`)), this.timeoutMS);
    });

    let res = {};
    let resolveFloodgate;
    const p = new Promise(resolve => {
      resolveFloodgate = resolve;
    });
    if (authentication) {
      this.floodgateAPIWithAuthPromise = p;
    } else {
      this.floodgateAPIPromise = p;
    }
    Promise.race([fetchPromise, fetchTimeout]).then(response => {
      res = response;
      const contentType = response.headers.get('content-type');
      if (response.ok && contentType && contentType.includes('application/json')) {
        // reset the error, just in case someone reset the readyPromise
        this.error = undefined;
        return response.json();
      }
      // We need to deference the text() Promise to get any error detail.
      return response.text().then(result => {
        const text = `Floodgate API failed with status ${
          response.status
        } ${
          response.statusText
        } type ${
          contentType
        } text ${
          result}`;
        throw new TypeError(text);
      });
    }).then(result => {
      // would be better if this were a finally block, except for  IE...
      clearTimeout(timeoutId);
      resolveFloodgate(result);
      return result;
    }).catch(err => {
      clearTimeout(timeoutId);
      const errorInfo = {
        auth: authentication.slice(0, 20),
        err,
        level: 'warn',
      };
      if (err instanceof TimeoutError) {
        logger.emit('Floodgate timeout', {
          ...errorInfo,
        });
      } else {
        logger.emit('Floodgate failure', {
          ...errorInfo,
          code: res.status,
          status_text: res.statusText,
        });
      }
      this.error = err.message ? err : new Error(`Floodgate failure: ${JSON.stringify(err)}`);
      resolveFloodgate(undefined);
    });
    return p;
  }
}

FloodgateProviderImpl.getInstance = getSingletonFunction(FloodgateProviderImpl);

export { getFeatureFlags, getMetaData };
export default FloodgateProviderImpl;
