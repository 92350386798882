/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import { Preset, LightBoxExitPDFViewerType } from "bundle/constants/ViewMode";
import { getCurrentEnv } from "bundle/util/EnvUtil";

export const defaultPreset = Preset.FULL_WINDOW;

export const INITIAL_CONFIG = {
    showFileLoadingProgress: true,
};

const DEFAULT_ANALYTICS_INFO = {
    callingApp: "",
    tenantId: "",
    externalUserId: "",
};

export const RESTRICTED_CONFIG = {
    showTopBar: true,
    showBranding: true,
    showLegalNotice: false,
    supportUrl: "",
    localizationStrings: {},
    analyticsInfo: DEFAULT_ANALYTICS_INFO,
    showBrandingTitle: true,
    leftAlignFileName: false,
    preCreatePDFInCombine: true,
    releaseYear: "2019",
    applicationVersion: "",
    showCloseButtonInDirectAction: true,
    enableUpsellFlow: true,
    showViewMode: true,
    showFileNameToast: undefined,
    showScrubber: undefined,
    disableUnsupportedFeatureAlert: false,
    showPreviewErrors: false,
    showRHP: true,
    showFullScreenWindowModal: false,
    executeDropinId: undefined,
    supportDarkMode: false,
    isViewSDKPublic: true,
    env: getCurrentEnv(window.location.host),
    linearizationBlockSize: 65536,
    suppressPasswordDialog: false,
    fileRenderingTimeout: 60000, // 1 minute
    renderWithV2SpectrumProvider: true,
    enableKillSwitch: false,
};

export const HIDDEN_CONFIG = {
    brandingTitleKey: "ADOBE_ACROBAT",
};

export const INITIAL_ACTION_CONFIG = {
    preview: {
        embedMode: defaultPreset,
        showAnnotationTools: true,
        enableAnnotationAPIs: false,
        includePDFAnnotations: false,
        showLeftHandPanel: true,
        showPageControls: true,
        showDownloadPDF: true,
        showPrintPDF: true,
        showFileStatusInfo: false,
        showFitPageButton: true,
        showFitWidthButton: true,
        showZoomControl: true,
        showPageNavigationControl: true,
        defaultViewMode: "",
        redirectAfterExportPDF: false,
        enableFormFilling: true,
        showDisabledSaveButton: false,
        annotationUIConfig: {
            // downloadWithAnnotations: false,
            // printWithAnnotations: false,
            // showCommentsPanel: true,
            // showToolsOnTextSelection: true,
            // showToolbar: true,
        },
        adjustSearchUI: true,
        adjustSearchUIBelowTopBar: true,
        enableSearchAPIs: false,
        enableLinearization: false,
        showPreviewHomeButton: false,
        useIframelessPrint: false,
        enableFreeTextAnnotationTool: true,
        enableModernViewer: false,
        enableFillSign: false,
        forceSetExternalMetadata: true,
        showBookmarks: true,
        showThumbnails: true,
        enableReadAloud: false,
        enablePdfRequestSignatures: false,
    },
};

export const RESTRICTED_ACTION_CONFIG = {
    preview: {
        docViewBgColor: "#CACACA",
        showRotateViewControl: false,
        showFullScreenInHUD: false,
        enableAutoFullScreen: false,
        localizationStrings: {},
        uiActionConfig: [],
        enableEraserAnnotationTool: true,
        enableFreeTextAnnotationTool: true,
        linearizationConfig: {},
        useIframelessPrint: false,
        enableReadAloud: false,
        enableModernViewer: true,
        fileLocationPromise: Promise.resolve(undefined),
        thumbnailSecondaryHeaderVerbs: [],
        enableAccessibilityByDefault: true,
    },
};

// Keeping a list of config, which will help us in future if we want to expose
export const HIDDEN_ACTION_CONFIG = {
    preview: {},
};

export const PRESET_INITIAL_CONFIG = {
    FULL_WINDOW: {
        config: {
            backgroundColor: "#eaeaea",
        },
        actionConfig: {
            focusOnRendering: true,
            showFullScreen: false,
            dockPageControls: true,
        },
    },
    LIGHT_BOX: {
        config: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
        },
        actionConfig: {
            exitPDFViewerType: LightBoxExitPDFViewerType.CLOSE,
            dockPageControls: false,
        },
    },
    SIZED_CONTAINER: {
        config: {
            backgroundColor: "#eaeaea",
        },
        actionConfig: {
            showFullScreen: true,
            dockPageControls: true,
            focusOnRendering: false,
        },
    },
    IN_LINE: {
        config: {
            backgroundColor: "#fff",
        },
        actionConfig: {
            showFullScreen: false,
            focusOnRendering: false,
        },
    },
};

export const PRESET_FORCE_CONFIG = {
    FULL_WINDOW: {
        config: {
            showBranding: true,
            showBrandingTitle: false,
            leftAlignFileName: true,
            showLegalNotice: true,
            supportUrl: "",
            localizationStrings: {},
            applicationVersion: "",
        },
        actionConfig: {
            enableAutoFullScreen: false,
            enableBookmarkAPIs: true,
            enableAttachmentAPIs: true,
            showFileDownloadInPageControl: false,
            showFullScreenInHUD: false,
            localizationStrings: {},
            exitPDFViewerType: "",
            saveBeforeUnload: true,
            showPreviewHomeButton: false,
            useIframelessPrint: false,
            executeDropinId: undefined,
        },
    },
    LIGHT_BOX: {
        config: {
            showBranding: true,
            showBrandingTitle: false,
            leftAlignFileName: true,
            showLegalNotice: true,
            supportUrl: "",
            localizationStrings: {},
            applicationVersion: "",
        },
        actionConfig: {
            showAnnotationTools: false,
            enableAnnotationAPIs: false,
            enableBookmarkAPIs: false,
            enableAttachmentAPIs: false,
            includePDFAnnotations: false,
            enableFormFilling: false,
            showLeftHandPanel: false,
            enableAutoFullScreen: false,
            showFileDownloadInPageControl: false,
            showFullScreenInHUD: false,
            showFullScreen: false,
            focusOnRendering: true,
            localizationStrings: {},
            enableLinearization: false,
            adjustSearchUI: false,
            saveBeforeUnload: true,
            showPreviewHomeButton: false,
            useIframelessPrint: false,
            executeDropinId: undefined,
        },
    },
    SIZED_CONTAINER: {
        config: {
            showTopBar: true,
            showBrandingTitle: false,
            showBranding: true,
            leftAlignFileName: true,
            showLegalNotice: true,
            supportUrl: "",
            localizationStrings: {},
            applicationVersion: "",
            saveBeforeUnload: false,
        },
        actionConfig: {
            showAnnotationTools: false,
            enableAnnotationAPIs: false,
            enableBookmarkAPIs: false,
            enableAttachmentAPIs: false,
            includePDFAnnotations: false,
            enableFormFilling: false,
            showLeftHandPanel: false,
            showPageControls: true,
            showRotateViewControl: false,
            showFitPageButton: false,
            showFitWidthButton: false,
            showZoomControl: false,
            defaultViewMode: "SINGLE_PAGE",
            enableAutoFullScreen: false,
            showFullScreenInHUD: true,
            showFileDownloadInPageControl: false,
            showPageNavigationControl: true,
            localizationStrings: {},
            exitPDFViewerType: "",
            enableLinearization: false,
            adjustSearchUI: false,
            saveBeforeUnload: true,
            showPreviewHomeButton: false,
            useIframelessPrint: false,
            executeDropinId: undefined,
        },
    },
    IN_LINE: {
        config: {
            showTopBar: false,
            showBrandingTitle: false,
            showBranding: true,
            leftAlignFileName: true,
            showLegalNotice: true,
            supportUrl: "",
            localizationStrings: {},
            applicationVersion: "",
        },
        actionConfig: {
            showAnnotationTools: false,
            enableAnnotationAPIs: false,
            enableBookmarkAPIs: false,
            enableAttachmentAPIs: false,
            includePDFAnnotations: false,
            enableFormFilling: false,
            showLeftHandPanel: false,
            showRotateViewControl: false,
            showPageControls: true,
            dockPageControls: false,
            showFitPageButton: false,
            showFitWidthButton: false,
            defaultViewMode: "IN_PLACE",
            showFullScreen: false,
            showFullScreenInHUD: false,
            showZoomControl: false,
            showFileDownloadInPageControl: true,
            showPageNavigationControl: true,
            localizationStrings: {},
            exitPDFViewerType: "",
            enableLinearization: false,
            adjustSearchUI: false,
            saveBeforeUnload: true,
            showBookmarks: false,
            showThumbnails: false,
            showPreviewHomeButton: false,
            useIframelessPrint: false,
            executeDropinId: undefined,
        },
    },
};

export const MODERN_VIEWER_CONFIG = {
    FULL_WINDOW: {
        config: {
            backgroundColor: "#F5F5F5",
            showBranding: true,
            leftAlignFileName: false,
        },
        actionConfig: {
            showLeftHandPanel: false,
            enableModernViewer: true,
        },
    },
    LIGHT_BOX: {
        config: {
            backgroundColor: "rgb(255,255,255, 0.8)",
            showBranding: true,
            leftAlignFileName: false,
        },
        actionConfig: {
            enableModernViewer: true,
            enableAttachmentAPIs: true,
            enableBookmarkAPIs: true,
        },
    },
    SIZED_CONTAINER: {
        config: {
            showTopBar: false,
            showBranding: true,
            leftAlignFileName: false,
        },
        actionConfig: {
            showFileNameToast: true,
            enableModernViewer: true,
            showAnnotationTools: false,
        },
    },
    IN_LINE: {
        config: {
            backgroundColor: "#fff",
        },
        actionConfig: {
            enableModernViewer: true,
        },
    },
};
