/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import ViewSDKInterfaceApp from "interface/ViewSDKInterfaceApp";
import AdobeDCView from "bundle/interface/AdobeDCViewBase";
import CallbackTypes from "common/constants/CallbackTypes";
import Events, {
    PDFAnalyticsEvents,
    AnnotationEvents,
    AnnotationTypes,
    FilePreviewEvents,
} from "bundle/constants/Events";
import ApiConstants from "bundle/constants/ApiConstants";
import ViewMode, { Preset } from "bundle/constants/ViewMode";
import MessagingService from "common/service/MessagingService";
import ValidationService from "bundle/service/ValidationService";
import DOMEventListenerService from "interface/service/DOMEventListenerService";
import PDFAnalyticsFeederService from "interface/service/PDFAnalyticsFeederService";
import AnalyticsSenderService from "interface/service/AnalyticsSenderService";
import CallbackService from "common/service/CallbackService";
import CommandSenderService from "interface/service/CommandSenderService";
import LicenseVerificationService from "interface/service/LicenseVerificationService";
import FullScreenMessageHandler from "interface/service/FullScreenMessageHandler";
import EmbedModeHandlerService from "interface/service/EmbedModeHandlerService";
import EventHandlerService from "interface/service/EventHandlerService";
import MessageSender from "interface/service/MessageSender";
import UIActionHandlerService from "interface/service/UIActionHandlerService";
import AdobeViewer from "bundle/interface/AdobeViewer";
import CommandManager from "interface/managers/CommandManager";
import AnnotationManager from "interface/managers/AnnotationManager";

/**
 * @classdesc
 * Adobe Document Cloud View class provides capability to preview/embed the PDF.
 * It is exposed under AdobeDC namespace and it exposes various View SDK methods.
 * @class
 */
export default class View extends AdobeDCView {
    _initServiceFactory(config) {
        this._serviceFactory.register("CallbackService", new CallbackService());
        this._serviceFactory.register("LicenseVerificationService", new LicenseVerificationService());
        this._serviceFactory.register("DOMEventListenerService", new DOMEventListenerService());
        this._serviceFactory.register("MessagingService", new MessagingService());
        this._serviceFactory.register("MessageSender", new MessageSender());
        this._serviceFactory.register("FullScreenMessageHandler", new FullScreenMessageHandler());
        this._serviceFactory.register("ValidationService", new ValidationService(true));
        this._serviceFactory.register("EventHandlerService", new EventHandlerService());
        this._serviceFactory.register("CommandSenderService", new CommandSenderService());
        this._serviceFactory.register("AnalyticsSenderService", new AnalyticsSenderService());
        this._serviceFactory.register("PDFAnalyticsFeederService", new PDFAnalyticsFeederService());
        this._serviceFactory.register("EmbedModeHandlerService", new EmbedModeHandlerService());
        this._serviceFactory.register("UIActionHandlerService", new UIActionHandlerService());
        this._serviceFactory.register("AdobeViewer", new AdobeViewer(this._serviceFactory, true));
        this._serviceFactory.register("AnnotationManager", new AnnotationManager(this._serviceFactory, true));
        this._serviceFactory.register("CommandManager", new CommandManager(this._serviceFactory, true));

        this._serviceFactory.register("ViewSDKInterfaceApp", new ViewSDKInterfaceApp(config, this._serviceFactory));

        const messagingService = this._serviceFactory.getService("MessagingService");
        const messageSender = this._serviceFactory.getService("MessageSender");
        this._serviceFactory.getService("EventHandlerService").initialize(messagingService);
        this._serviceFactory.getService("DOMEventListenerService").initialize(messageSender);
        this._serviceFactory.getService("CallbackService").initialize(messagingService);
        this._serviceFactory.getService("LicenseVerificationService").initialize(messagingService);
        this._serviceFactory.getService("CommandSenderService").initialize(messagingService);
    }

    /**
     * Enum for Callback, Events and others
     * @type {enum}
     */
    static get Enum() {
        return {
            CallbackType: CallbackTypes,
            ApiResponseCode: ApiConstants.API_RESPONSE,
            Events,
            PDFAnalyticsEvents,
            FilePreviewEvents,
            AnnotationTypes,
            AnnotationEvents,
            ViewMode,
            EmbedMode: Preset,
        };
    }
}

window.AdobeDC = window.AdobeDC || {};
window.AdobeDC.View = View;

(function (event) { // eslint-disable-line
    event.initEvent("adobe_dc_view_sdk.ready", true, true);
    document.dispatchEvent(event);
}(document.createEvent("Event")));
