/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2019 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
import CommandManagerBase from "bundle/managers/CommandManager";
import uuidV4 from "uuid/v4";
import { AppAPICommands, PreviewAPICommands } from "bundle/constants/APICommands";
import SearchManager from "interface/managers/SearchManager";

export default class CommandManager extends CommandManagerBase {
    /**
     * @description
     * executeCommand(command, commandData) method to execute commands on file preview.
     * @method
     * @param {String} command - Command which we wanted to execute on file preview
     * @param {Object} commandData - data which should be passed with command.
     * @returns {Promise} - promise that resolves with result of command.
     * @public
     */
    executeCommand(command, commandData) {
        if (AppAPICommands[command] || PreviewAPICommands[command]) {
            return this._serviceFactory.getService("CommandSenderService").sendCommand(command, commandData);
        }
        return Promise.reject({ message: `Command ${command} not supported.` });
    }

    search(searchText) {
        const searchId = uuidV4();
        this._searchManager = new SearchManager(searchId, this._serviceFactory);
        return this.executeCommand(PreviewAPICommands.START_SEARCH, { searchId, searchText })
            .then(() => this._searchManager.getExposedAPIs());
    }
}
