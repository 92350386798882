/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";

export default class LicenseVerificationService {
    initialize(messagingService) {
        this._messagingService = messagingService;
        this._messagingService.listenForType(
            IFrameMessageTypes.LICENSE_CALL_INIT,
            this._executeLicenseCall,
        );
    }

    _executeLicenseCall = typeData => {
        const resultType = IFrameMessageTypes.LICENSE_CALL_RESULT;
        const sendToCore = resultData => this._messagingService.sendMessage(resultType, resultData);
        let statusCode = 0;
        fetch(typeData.url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: typeData.headers,
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(typeData.body),
        })
            .then(response => {
                statusCode = response.status;
                return response.json();
            })
            .then(response => sendToCore({ callId: typeData.callId, response, statusCode }))
            .catch(errorObj => sendToCore({
                callId: typeData.callId,
                error: {
                    code: errorObj.code,
                    message: errorObj.message,
                },
                statusCode,
            }));
    };
}
