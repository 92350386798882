/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
import getUrlContent, { getInfo, getFileBufferRanges } from "bundle/util/FileLoader";
import { Preset } from "bundle/constants/ViewMode";
import Events, { InternalEvents } from "bundle/constants/Events";
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";
import {
    addLBFullScreenToElement, allowOverflow, blockOverflow, cleanUpElement,
} from "interface/util/StylesUtil";
import { InternalCallbackTypes } from "common/constants/CallbackTypes";
import smoothscroll from "smoothscroll-polyfill";
import { PRESET_INITIAL_CONFIG, PRESET_FORCE_CONFIG } from "app/constants/ConfigStoreConstants";

window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

export default class EmbedModeHandlerService {
    constructor() {
        this._listenOnViewUpdate = false;
        this._currentDivHeight = 0;
        this._firstDVUEvent = true;
        this._previewPromise = undefined;
        this._previewPromiseResolve = undefined;
        this._previewPromiseReject = undefined;
        this._previewPromiseDone = false;
        this._previewConfig = undefined;
    }

    initialize(config, serviceFactory) {
        this._config = config;
        this._serviceFactory = serviceFactory;
        if (this._config.parentDivId) {
            this._parentDivElement = window.document.getElementById(this._config.parentDivId);
        }
        this._appInitializeReceivedOn = new Date().getTime();
        this._messagingService = this._serviceFactory.getService("MessagingService");
        this._fullScreenMessageHandler = this._serviceFactory.getService("FullScreenMessageHandler");
        this._validationService = this._serviceFactory.getService("ValidationService");
        this._eventHandlerService = this._serviceFactory.getService("EventHandlerService");
        this._domEventListenerService = this._serviceFactory.getService("DOMEventListenerService");
        this._uiActionHandlerService = this._serviceFactory.getService("UIActionHandlerService");
        this._callbackService = this._serviceFactory.getService("CallbackService");
        this._registerPreviewEvents();
    }

    initialiseAction(fileInfos, origPreviewConfig, messageType, hostAppStartTime) {
        const actionReceivedOn = new Date().getTime();
        const heightInView = this._getHeightInView(window.document.getElementById(this._config.divId));
        const embedMode = origPreviewConfig.embedMode ? origPreviewConfig.embedMode.toUpperCase()
            : (origPreviewConfig.embedMode || Preset.FULL_WINDOW);
        const previewConfig = { ...origPreviewConfig, embedMode };
        previewConfig.enableModernViewer = true;

        delete previewConfig.preset;
        if (previewConfig.embedMode === "IN_PLACE") {
            previewConfig.embedMode = Preset.IN_LINE;
        }
        if (previewConfig.embedMode === Preset.LIGHT_BOX) {
            previewConfig.focusOnRendering = PRESET_FORCE_CONFIG[previewConfig.embedMode].actionConfig.focusOnRendering;
        }

        if (!("focusOnRendering" in previewConfig)) {
            // eslint-disable-next-line max-len
            previewConfig.focusOnRendering = PRESET_INITIAL_CONFIG[previewConfig.embedMode].actionConfig.focusOnRendering;
        }

        this._validationService.validatePreviewConfig(previewConfig);

        this._previewConfig = previewConfig;

        this._validationService.validateFileInfoForPreviewConfig(fileInfos, previewConfig);
        const isPreviewAction = messageType === IFrameMessageTypes.PREVIEW;
        const fileInfo = [this._getFinalFileInfo(fileInfos, isPreviewAction)];

        this._uiActionHandlerService.initialize(previewConfig.uiActionConfig, this._serviceFactory);
        previewConfig.uiActionConfig = this._uiActionHandlerService.getProcessedUIActionConfig();
        const timeInfo = {
            actionReceivedOn,
            hostAppStartTime,
            appInitializeReceivedOn: this._appInitializeReceivedOn,
        };
        const actionData = {
            previewConfig,
            fileInfo,
            heightInView,
            timeInfo,
        };
        this._messagingService.sendMessage(messageType, actionData);
        this._previewPromise = new Promise((resolve, reject) => {
            this._previewPromiseResolve = resolve;
            this._previewPromiseReject = reject;
        });
        this._handlePresetSpecific(previewConfig);
        return this._previewPromise;
    }

    _updateViewHeight(viewUpdate) {
        if (this._fullScreenMessageHandler.isFullScreen() || !this._listenOnViewUpdate) {
            return;
        }
        const doc = window.document;
        const divElement = doc.getElementById(this._config.divId);
        if (typeof viewUpdate.scrollHeight !== "undefined") {
            const finalHeight = viewUpdate.scrollHeight + 20;
            if (finalHeight !== this._currentDivHeight) {
                divElement.style.height = `${ finalHeight }px`;
                this._currentDivHeight = finalHeight;
                this._messagingService.sendMessage(IFrameMessageTypes.UPDATE_HEIGHT, {
                    height: this._currentDivHeight,
                });
            }
        }
        if (typeof viewUpdate.y !== "undefined" && !this._firstDVUEvent) {
            let divElementTop = divElement.getBoundingClientRect().top + window.pageYOffset;
            if (this._parentDivElement) {
                divElementTop = divElement.offsetTop - this._parentDivElement.offsetTop;
            }
            const scrollTo = Math.floor(viewUpdate.y + divElementTop);
            if (this._parentDivElement) {
                this._parentDivElement.scrollTop = scrollTo;
            } else {
                window.scrollTo({
                    top: scrollTo,
                    behavior: this._previewConfig.embedMode === Preset.IN_LINE ? "smooth" : "auto",
                });
            }
        }
    }

    _handlePresetSpecific(previewConfig) {
        const divElement = window.document.getElementById(this._config.divId);
        if (divElement.internallyCreated && previewConfig.embedMode !== Preset.LIGHT_BOX) {
            divElement.parentNode.removeChild(divElement);
            throw new Error(`DivId ${this._config.divId} is not present in HTML document.`);
        }

        if (previewConfig.embedMode === Preset.IN_LINE) {
            this._listenOnViewUpdate = true;
            const sendHeightMessage = event => {
                if (this._serviceFactory.getService("MessageSender").isInstanceStillAlive()) {
                    let data;
                    // TODO add proper handling by creating a Callback
                    if (event && event.detail && event.detail.type === "DC_PDF_VIEWER_VIEW_UPDATE") {
                        data = event.detail.data;
                    } else {
                        data = this._getHeightInView(divElement);
                    }
                    this._messagingService.sendMessage(IFrameMessageTypes.HEIGHT_IN_VIEW, data);
                }
            };
            this._previewPromise.then(() => {
                sendHeightMessage(); // required to correct the header if not correct.
                this._addInLineListeners(sendHeightMessage);
            });
            this._updateViewHeight({ scrollHeight: 600 });
        } else if (previewConfig.embedMode === Preset.LIGHT_BOX) {
            if (!this._parentDivElement) {
                const tempDivElement = window.document.createElement("div");
                tempDivElement.id = `${this._config.divId}_temp`;
                tempDivElement.innerHTML = "<div class='ViewSDK_LBLoader'></div>";
                window.document.body.appendChild(tempDivElement);
                addLBFullScreenToElement(tempDivElement);
            }
            this._messagingService.listenForType(
                IFrameMessageTypes.LB_OPEN_PDF_VIEWER,
                this._LBOpenPDFViewerMessageReceived,
            );
            this._messagingService.listenForType(
                IFrameMessageTypes.LB_EXIT_PDF_VIEWER,
                this._LBExitPDFViewerMessageReceived,
            );
        }
        this._eventHandlerService.registerEventListener(
            Events.PDF_VIEWER_OPEN,
            this._PDFViewerOpened,
        );
    }

    // Focus when file is opened
    _PDFViewerOpened = () => {
        if (this._previewConfig.focusOnRendering) {
            window.setTimeout(() => {
                window.document.getElementById(this._config.iframeId).focus();
            }, 0);
        } else {
            window.setTimeout(() => {
                window.document.getElementById(this._config.iframeId).blur();
            }, 0);
        }
    };

    // Light Box embed mode specific actions for when file is opened
    _LBOpenPDFViewerMessageReceived = typeData => {
        const divElement = window.document.getElementById(this._config.divId);
        if (this._parentDivElement) {
            cleanUpElement(divElement);
        } else {
            this._fullScreenMessageHandler.fullScreenOn(divElement, typeData.isIOS);
            this._eventHandlerService.registerEventListener(
                Events.APP_RENDERING_START,
                () => {
                    setTimeout(() => {
                        const tempDivElement = window.document.getElementById(`${this._config.divId}_temp`);
                        tempDivElement.parentNode.removeChild(tempDivElement);
                    }, 10);
                },
            );
        }
        this._disableBackgroundScroll();
    };

    // Light Box embed mode specific actions for when file is closed
    _LBExitPDFViewerMessageReceived = () => {
        const divElement = window.document.getElementById(this._config.divId);
        if (divElement.internallyCreated) {
            divElement.parentNode.removeChild(divElement);
        } else {
            cleanUpElement(divElement);
            divElement.innerHTML = "";
        }
        this._enableBackgroundScroll();
    };

    _getHeightInView(divElement) {
        let topBuffer = 0;
        let bottomBuffer = 0;
        if (!this._fullScreenMessageHandler.isFullScreen() && this._parentDivElement) {
            const rect = this._parentDivElement.getBoundingClientRect();
            topBuffer = Math.ceil(rect.top);
            bottomBuffer = window.innerHeight - topBuffer - rect.height;
        }
        const rect = divElement.getBoundingClientRect();
        const top = rect.top;
        const bottom = rect.bottom;
        const divHeight = rect.height;
        const winHeight = window.innerHeight;
        const winWidth = window.innerWidth;
        let outOfView = 0;
        let viewStartH = 0;
        let viewEndH = 0;
        if (top > (winHeight - bottomBuffer)) {
            outOfView = 1;
        } else if (bottom < topBuffer) {
            outOfView = 1;
            viewStartH = divHeight;
            viewEndH = divHeight;
        } else if (top < topBuffer) {
            viewStartH = (-top) + topBuffer;
            viewEndH = (viewStartH + winHeight) - topBuffer - bottomBuffer;
            if (viewEndH > divHeight) {
                viewEndH = divHeight;
            }
        } else {
            viewStartH = 0;
            viewEndH = winHeight - top - bottomBuffer;
        }
        return {
            viewStartH,
            viewEndH,
            outOfView,
            winHeight: winHeight - topBuffer - bottomBuffer,
            winWidth,
        };
    }

    _disableBackgroundScroll() {
        blockOverflow(this._parentDivElement ? this._parentDivElement : window.document.body);
    }

    _enableBackgroundScroll() {
        allowOverflow(this._parentDivElement ? this._parentDivElement : window.document.body);
    }

    _addInLineListeners(callback) {
        if (this._parentDivElement) {
            this._domEventListenerService.listen(this._parentDivElement, "scroll", callback);
            this._domEventListenerService.listen(this._parentDivElement, "touchend", callback);
        } else {
            this._domEventListenerService.listen(window, "scroll", callback);
            this._domEventListenerService.listen(window, "touchend", callback);
        }
        this._domEventListenerService.listen(window, "resize", callback);
        this._domEventListenerService.listen(window, "orientationchange", callback);
    }

    _getFinalFileInfo(fileInfo, isPreviewAction = false) {
        this._validationService.validateFileInfo(fileInfo);
        const newFileInfo = {
            metaData: fileInfo.metaData,
        };
        if (fileInfo.rendition) {
            const rendition = {};
            Object.keys(fileInfo.rendition).forEach(pageIndex => {
                rendition[pageIndex] = this._processContentMetadata(fileInfo.rendition[pageIndex], {
                    pageIndex,
                    isPageRendition: true,
                });
            });
            newFileInfo.rendition = rendition;
        }
        if (fileInfo.content) {
            newFileInfo.content = this._processContentMetadata(fileInfo.content, {
                callbackFn: this.fileProgressLoadingData.bind(this),
                isPreviewAction,
            });
        }
        return newFileInfo;
    }

    fileProgressLoadingData(loaded, total) {
        const mType = IFrameMessageTypes.FILE_LOADING_PROGRESS_DATA;
        const progressData = {
            loaded,
            total,
        };
        this._messagingService.sendMessage(mType, progressData);
    }

    _processContentMetadata(content, options) {
        const newContent = {};
        if (content.promise) {
            newContent.promise = this._messagingService.sendPromise(content.promise);
        } else if (content.location) {
            // fetching file contents from location URL only in case of preview action
            if (options.isPreviewAction && !this._previewConfig.enableLinearization) {
                const params = {
                    downloadWithCredentials: this._config.downloadWithCredentials,
                };
                const filePromise = getUrlContent(content.location, options.callbackFn, params).then(response => {
                    if (!options.isPageRendition) {
                        return response.buffer;
                    }
                    return {
                        pageImgBuffer: response.buffer,
                        mimeType: response.mimeType,
                        pageNum: options.pageIndex,
                    };
                });
                newContent.promise = this._messagingService.sendPromise(filePromise);
            }
        }
        if (this._previewConfig.enableLinearization) {
            Object.assign(newContent, this._processContentMetadataForLinearization(content, options));
        }

        newContent.location = content.location;
        return newContent;
    }

    _processContentMetadataForLinearization(content, options) {
        const updatedContent = {};
        let infoPromise;
        let initialBufferPromise;
        let getFileBufferRangesCallback;
        let filePromise;
        if (content.linearizationInfo) {
            const linearizationInfo = content.linearizationInfo;
            infoPromise = linearizationInfo.getInfo();
            initialBufferPromise = linearizationInfo.getInitialBuffer();
            getFileBufferRangesCallback = (...args) => linearizationInfo.getFileBufferRanges.call(null, ...args);
        } else {
            const params = {
                downloadWithCredentials: this._config.downloadWithCredentials,
            };
            infoPromise = getInfo(content.location, params);
            initialBufferPromise = getFileBufferRanges(content.location, [{ start: 0, end: 1024 }], params)
                .then(result => ({ buffer: result.bufferList[0] }));
            if (this._previewConfig.linearizationConfig
                && this._previewConfig.linearizationConfig.initiateEarlyFileDownload) {
                filePromise = getUrlContent(content.location, options.callbackFn, params)
                    .then(response => response.buffer);
            } else {
                filePromise = new Promise((resolve, reject) => {
                    initialBufferPromise
                        .finally(() => getUrlContent(content.location, options.callbackFn, params)
                            .then(result => resolve(result.buffer))
                            .catch(err => reject(err)));
                });
            }
            updatedContent.promise = this._messagingService.sendPromise(filePromise);
            getFileBufferRangesCallback = (...args) => getFileBufferRanges
                .call(null, content.location, ...args, params);
        }

        updatedContent.infoPromise = this._messagingService.sendPromise(infoPromise);
        updatedContent.initialBufferPromise = this._messagingService.sendPromise(initialBufferPromise);
        this._callbackService
            .registerCallback(InternalCallbackTypes.GET_FILE_BUFFER_RANGES, getFileBufferRangesCallback, {});

        return updatedContent;
    }

    _registerPreviewEvents() {
        this._eventHandlerService.registerEventListener(InternalEvents.FILE_PREVIEW_READY, this._previewReady);
        this._eventHandlerService.registerEventListener(InternalEvents.FILE_PREVIEW_FAILED, this._previewFailure);
        this._eventHandlerService.registerEventListener(InternalEvents.DOCUMENT_VIEW_UPDATE, this._viewUpdate);
    }

    _previewReady = () => {
        if (!this._previewPromiseDone) {
            this._previewPromiseDone = true;
            this._previewPromiseResolve();
        }
    };

    _previewFailure = event => {
        if (!this._previewPromiseDone) {
            this._previewPromiseDone = true;
            this._previewPromiseReject(event.data);
        }
    };

    _viewUpdate = event => {
        this._updateViewHeight(event.data);
        this._firstDVUEvent = false;
    };
}
