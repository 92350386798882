/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2019 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

export const InternalCallbackTypes = {
    ON_SEARCH_RESULTS_UPDATE: "ON_SEARCH_RESULTS_UPDATE",
    GET_FILE_BUFFER_RANGES: "GET_FILE_BUFFER_RANGES",
};

const CallbackTypes = {
    SAVE_API: "SAVE_API",
    STATUS_API: "STATUS_API",
    EVENT_LISTENER: "EVENT_LISTENER",
    SET_USER_SETTING_API: "SET_USER_SETTING_API",
    GET_USER_SETTING_API: "GET_USER_SETTING_API",
    GET_USER_PROFILE_API: "GET_USER_PROFILE_API",
};

export default CallbackTypes;
