/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* global document, window, CustomEvent */
/* eslint import/prefer-default-export: 0 */

/**
 * @file
 * @description
 * Supports interaction with IMS authentication and authorization.
 */

import {
  getItem,
  setItem,
  removeItem,
  hasItem,
} from '../core/CookieUtil';

import { isProd } from '../core/EnvUtil';

/**
 * Private data for the Auth2API
 */
let config;

const state = {
  /** true if the user has signed in */
  isSignedIn: undefined,

  /** true if the user has started to sign out */
  isSigningOut: undefined,

  /** Will be used as the redirect URL when the user is signed out. */
  signOutRedirect: undefined,
};

const stateListeners = [];
let resolveSignIn;
const signInPromise = new Promise(resolve => {
  resolveSignIn = resolve;
});

/**
   * @description
   * Set the flag for user sign in, and notify listeners
   * @param {boolean} isSignedIn - The state to be set to user sign flag
   */
const setSignedIn = isSignedIn => {
  state.isSignedIn = isSignedIn;
  stateListeners.forEach(l => l(state));
  if (isSignedIn) resolveSignIn();
};

/**
   * @description
   * Set the flag that the user is signing out
   * @param {boolean} isSigningOut - The state to be set to user signing out flag
   */
const setSigningOut = isSigningOut => {
  state.isSigningOut = isSigningOut;
  stateListeners.forEach(l => l(state));
};

/**
   * @description
   * Sets the redirect URL for when the user is signed out.
   * @kind action
   * @param {string} signOutRedirect - The redirect URL to set.
   */
const setSignOutRedirect = signOutRedirect => {
  state.signOutRedirect = signOutRedirect;
  stateListeners.forEach(l => l(state));
};

const removePersonalizedAdsCookies = () => {
  // Optanon is not always loaded. See https://wiki.corp.adobe.com/display/adobedotcom/Privacy FAQ
  let optAnonPromise;
  if (window.Optanon) {
    optAnonPromise = Promise.resolve(window.Optanon);
  } else {
    if (!window.adobePrivacy) {
      // adobe privacy probably has not loaded. Reject so that the caller can retry
      return Promise.reject(new Error('adobePrivacy not defined'));
    }
    optAnonPromise = window.adobePrivacy.loadOneTrust().then(() => window.Optanon);
  }
  return optAnonPromise.then(optAnon => {
    // https://wiki.corp.adobe.com/display/privacyatadobe/Optanon+JavaScript+Wrapper
    // We only get cookies in category 'C0004' which corresponds to Personalize Advertising related cookies
    const groups = optAnon.GetDomainData()?.Groups ?? [];
    const marketing = groups.find(el => el.OptanonGroupId === 'C0004');
    if (marketing) {
      marketing.Cookies.forEach(cookie => {
        if (hasItem(cookie.Name)) {
          removeItem(cookie.Name);
        }
      });
    }
  });
};

const turnOffPersonalizedAdsConsent = () => {
  /**
   * There is a situation where the cookie value encoding is mixed up.
   *
   * Workflows: If you’re coming from a non-GDPR enforced country and it’s your first visit,
   * PrivacyJS will set a cookie with implicit consent
   * OptanonConsent=groups=C0001:1,C0002:1,C0003:1,C0004:1  unencoded and
   * skip loading the OneTrust scripts. As soon as you click on “Cookie Preferences”,
   * PrivacyJS will load the OneTrust scripts which format the existing
   * OptanonConsent  cookie by adding timestamp (consent) data, location data, version data etc;
   * the value is then encoded. OneTrust is able to read the unencoded value and then encode it.
   * This will not happen for users who are coming from a GDPR enforced country.
   *
   * To deal with this situation, we check for both encoded and non-encoded values
   */
  const optanonConsent = getItem('OptanonConsent');
  if (optanonConsent && ((optanonConsent.indexOf('C0004:1') !== -1) || optanonConsent.indexOf(encodeURIComponent('C0004:1')) !== -1)) {
    const optanonConsentFixed = optanonConsent
      .replace('C0004:1', 'C0004:0')
      .replace(encodeURIComponent('C0004:1'), encodeURIComponent('C0004:0'));
    const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    /**
     * On dev and stage, because the domainID is set to -test,
     * then OneTrust will write a cookie on the hostname (e.g. stage.acrobat.adobe.com instead of .adobe.com).
     * If we set the cookie .adobe.com in this case, we end up with 2 different cookies
     * which does not perform the correct override.
     * This situation should not happen in prod since there we set .adobe.com domain
     */
    const domain = isProd() ? '.adobe.com' : undefined;
    setItem('OptanonConsent', optanonConsentFixed, {
      domain,
      expires: oneYearFromNow.toUTCString(),
      path: '/',
      samesite: 'Lax',
    });
  }
};

// const privateData = new PrivateData();

/**
 * @class
 */
class Auth2API {
  constructor() {
    this.setConfig();

    const ready = () => {
      this.getUserProfileAsync();
      setSignedIn(!this.ims ? undefined : !!this.ims.isSignedInUser());
    };

    const addIMSEventListener = () => {
      if (this.ims.version.lastIndexOf('v2-', 0) === 0) {
        document.addEventListener('dc.imslib.ready', ready);
      } else {
        document.addEventListener('imslib.ready', ready);
      }
    };
    // imslibReady is a special event to avoid hardcoding the value of
    if (this.ims && this.ims.isReady && this.ims.isReady()) ready();
    // adobeIMS.DomEvents.READY is "imslib.ready"
    else if (window.adobe_dc_sdk && window.adobe_dc_sdk.imsReady) {
      ready();
    } else if (this.ims) {
      addIMSEventListener();
    } else if (window.adobe_dc_sdk && window.adobe_dc_sdk.loaded) {
      window.adobe_dc_sdk.loaded.ims.then(() => addIMSEventListener());
    }
  }

  /**
   * @description
   * Register a listener for state changes.
   * The function provided here can be the function returned by a React useState call.
   * The listener should be configured to listen to a state object with this schema:
      {
        isSignedIn: <bool>,
        isSigningOut: <bool>,
        signOutRedirect: <bool>
      }
   * @method
   * @param {function} listener - Object with required configuration
   * @public
   */
  useState(listener) {
    stateListeners.push(listener);
  }

  /**
   * @description
   * Configures auth provider
   * @method
   * @param {object} config - Object with required configuration
   * @public
   */

  setConfig(c) {
    config = c || {};
  }

  /**
   * @description
   * Returns auth provider config
   * @method
   * @returns {object} config - Configuration object
   * @public
   */

  get config() {
    return config;
  }

  /**
   * @description
   * To get the IMS API implementation
   * @method
   * @returns {object} - Implementation of IMS API.
   * @public
   */
  get ims() {
    return window.adobeIMS;
  }

  /**
   * @description
   * Tells whether user is signed in.
   */
  get isSignedIn() {
    return state.isSignedIn;
  }

  /*
   * Get a promise that resolves when a user has signed in
   */
  get signInPromise() {
    return signInPromise;
  }

  /**
   * @description
   * Tells whether user has started signing out.
   */
  get isSigningOut() {
    return state.isSigningOut;
  }

  /**
   * @description
   * Gets the signOutRedirect that will be used when a user is signed out.
   * @method
   * @returns {String} - The URL to use for the redirect.
   * @public
   * @kind computed
   */
  get signOutRedirect() {
    return state.signOutRedirect;
  }

  /**
   * @description
   * Sets the signOutRedirect that will be used when a user is signed out.
   * @method
   * @param {String} redirect - The URL to use for the redirect.
   * @public
   */
  set signOutRedirect(redirect) {
    setSignOutRedirect(redirect);
  }

  /**
   * @description
   * To check if we have an access token
   * @method
   * @returns {Boolean} - Whether we have an access token.
   * @public
   */
  hasAccessToken() {
    return this.getAccessTokenInfo().access_token !== '';
  }

  /**
   * @description
   * To get the user profile
   * @method
   * @returns {object} - User profile
   * @public
   */
  getUserProfile = () => window.adobe_dc_sdk.ims_profile || { name: 'Anonymous' };

  /**
   * @description
   * To get the user profile async method
   * @method
   * @returns {object} - User profile
   * @public
   */
  getUserProfileAsync = async () => {
    if (this.ims.isSignedInUser()) {
      window.adobe_dc_sdk.ims_profile = await this.ims.getProfile();
    } else {
      window.adobe_dc_sdk.ims_profile = { name: 'Anonymous' };
    }
    return window.adobe_dc_sdk.ims_profile;
  };

  /**
   * @description
   * To get the access token information
   * @method
   * @returns {object} - object with access token and related info.
   * - access_token: IMS access token
   * - expires_in: How long it's valid
   * - sid: Session identifier
   * - token_type: In our case, 'bearer'
   * @public
   */
  getAccessTokenInfo() {
    let tokenInformation;
    let expiresIn;
    let accessToken;
    let sid;

    if (this.ims) {
      if (this.ims.version.startsWith('v2-')) {
        tokenInformation = this.ims.getAccessToken();
        expiresIn = tokenInformation && tokenInformation.expire ? tokenInformation.expire - Date.now() : 0;
        accessToken = tokenInformation ? tokenInformation.token : '';
        sid = tokenInformation ? tokenInformation.sid : undefined;
      } else {
        tokenInformation = this.ims.getAccessTokenInfo();
        expiresIn = tokenInformation ? tokenInformation.expires_in : 0;
        accessToken = tokenInformation ? tokenInformation.access_token : '';
        sid = undefined;
      }
    }

    return {
      access_token: accessToken,
      expires_in: expiresIn,
      sid,
      token_type: 'bearer',
    };
  }

  /**
   * @description
   * To get the access token information.
   * Refresh token in case access token is expired.
   * @method
   * @returns Promise<{object}> - object with access token and related info.
   * - access_token: IMS access token
   * - expires_in: How long it's valid
   * - sid: Session identifier
   * - token_type: In our case, 'bearer'
   * @public
   */
  getAccessTokenInfoAsync = async () => {
    if (this.isSignedIn && !this.hasAccessToken()) {
      await this.refreshAccessToken();
    }
    return this.getAccessTokenInfo();
  }

  /**
   * @description
   * Method to satisfy the DC API IdentityAccess interface to get access
   * token and related info.
   * @method
   * @returns {Promise} -  Promise for an Object with following properties on success:
   *   status: 200, content: {
   *      access_token: (String) Access, or session, token
   *      token_type: (String) Usually "bearer"
   *      expires_in: (Number) # msecs until token expiration
   *   }
   * @public
   */
  getSessionToken = async () => {
    const tokenInfo = await this.getAccessTokenInfoAsync().catch(() => this.getAccessTokenInfo());
    return {
      status: 200,
      content: tokenInfo,
    };
  }

  /**
   * @description
   * Signs in user.
   * @method
   * @public
   *
   * @param {Object} customParams - Customize IMS sign-in options.
   * For example to provide login user hint pass customParams as {puser: <email>}
   */
  signIn(customParams) {
    setSigningOut(false);
    this.ims.signIn(customParams || {});
  }

  /**
   * @description
   * Signs out user, setting the isSigningOut observable true.
   * @method
   * @public
   *
   * @param {String} redirectUri - Where to go after signout.
   */
  signOut(redirectUri) {
    let accessToken; let xSignOutHost; let
      xSignOutUri;
    const customParams = {};
    const accessTokenInfo = this.getAccessTokenInfo();

    setSigningOut(true);
    if (redirectUri) {
      xSignOutHost = config.xSignOutHost;
      if (accessTokenInfo) {
        accessToken = accessTokenInfo.access_token;
      }
      // Tell IMS to redirect to xSignOutHost which will then redirect to redirectUri
      if (xSignOutHost && accessToken) {
        redirectUri = encodeURIComponent(redirectUri);
        xSignOutUri = `${xSignOutHost}/xsignout?redirect_uri=${redirectUri}&access_token=${accessToken}`;
        customParams.redirect_uri = xSignOutUri;
      } else {
        customParams.redirect_uri = redirectUri;
      }
    }
    this.ims.signOut(customParams);
  }

  /**
   * @description
   * This sets the isSigningOut observable for cases of a separate call to adobeIMS signOut(),
   * e.g. from the CC-DC utilnav.
   * @method
   * @public
   *
   * @param {Boolean} bool - The boolean value to set for the signing out state.
   */
  setSigningOut(bool) {
    setSigningOut(bool);
  }

  /**
   * @description
   * Toggles user's signed-in state.
   * @method
   * @param {String} [redirectUri] - The URI to be redirected to after we sign out the user. If not given, it will
   * default to the previously provided signOutRedirect (e.g. auth.signOutRedirect = "...").
   * @public
   */
  toggleSignedIn(redirectUri) {
    if (this.isSignedIn) {
      this.signOut(redirectUri || this.signOutRedirect);
    } else {
      this.signIn();
    }
  }

  /**
   * @description
   * Refreshes user's access token.
   * @method
   * @returns {Promise} Promise which resolves when refresh is complete
   * @public
   */
  refreshAccessToken() {
    if (this.ims.version.startsWith('v2-')) {
      return this.ims.refreshToken();
    }
    return new Promise((resolve, reject) => {
      if (this.isSignedIn) {
        this.ims.acquireAccessToken(
          () => {
            resolve();
          },
          err => {
            reject(err.stack || err.message);
          },
        );
      } else {
        reject(new ReferenceError('Not signed-in!'));
      }
    });
  }

  /**
   * @description
   * When additional scope additional_info.incomplete,additional_info.creation_source is
   * passed to IMS then user profile method will have information about creation source and account completion status.
   * @method
   * @returns {boolean} Flag indicating whether account is complete or not
   */
  isIncompleteAccount = () => {
    if (this.isSignedIn) {
      const { creation_source: creationSource, incomplete } = this.getUserProfile();
      return creationSource === 'PROGRESSIVE_ACCOUNT' && incomplete;
    }
    return false;
  }

  removeProfile = () => {
    this.ims.profileService.removeProfile();
  }

/**
   * @description
   * callback function to be called after sign in completed for modalMode, which removes old profile and gets new profile.
   * dispatched new custom event 'dc.imslib.user_profile_updated' after getting new profile.
   * @method
   * @returns {undefined}
   */
_modalModeSignCompleteCallback = async () => {
  this.removeProfile();
  await this.getUserProfileAsync();
  window.dispatchEvent(new CustomEvent('dc.imslib.user_profile_updated'));
}

  /**
   * @description
   * Redirects the user to SUSI force account completion screen or Opens SUSI Modal for sign in based on modalSettings presence.
   * @method
   * @param {Object} options signin params modalSettings Refer https://wiki.corp.adobe.com/display/ims/IMS+authorize+entry+point#IMSauthorizeentrypoint-Requestsyntax
   * options.modalSettings - Refer https://git.corp.adobe.com/pages/IMS/imslib2.js/classes/_adobe_ims_sign_in_popupsettings_.popupsettings.html
   * @returns {undefined}
   */
  forceCompleteAccount = (options = {}) => {
    if (this.isIncompleteAccount()) {
      const signInPayload = {
        force_complete_account: true,
        redirect_uri: options.redirect_uri,
        dctx_id: options.dctx_id,
      };
      const adobeId = { ...window.adobeid };
      if (options.modalSettings) { // Using Modal mode for sign in
        adobeId.modalMode = true;
        adobeId.modalSettings = options.modalSettings;
        adobeId.onModalModeSignInComplete = this._modalModeSignCompleteCallback;
      } else { // In case of redirecting to SUSI without modal
        // Clearing the cached profile data so that profile gets refreshed after force completion of account.
        this.removeProfile();
      }
      // Trigger complete account via either modal-mode / redirect-mode at runtime based on options.modalSettings presence.
      // createIMSLib - Refer https://git.corp.adobe.com/IMS/imslib2.js/blob/ae52b19bbd9e00c3bfc949218a1de7700e5b434f/src/ImsInitialization.ts
      const imsLibInstance = window.adobeImsFactory.createIMSLib(adobeId);
      imsLibInstance.signIn(signInPayload);
    }
  }

  /**
   * @description
   * Determines if the user is an edu users (students/teachers).
   * We use the rules outlined in this wiki
   * https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=privacyatadobe&title=Tag-Based+Universal+Student+Account+Identification+for+Student+Protection#TagBasedUniversalStudentAccountIdentificationforStudentProtection-StudentAccountIdentificationRule
   * @method
   * @returns {Boolean} true if the user is edu, false otherwise
   */
  isEduUser = () => {
    const { tags } = this.getUserProfile();
    if (tags) {
      const eduTags = ['edu', 'edu_k12', 'edu_hed', 'edu_student'];
      return !!tags.find(tag => eduTags.includes(tag.toLowerCase()));
    }
    return false;
  }

  /**
   * @description
   * In compliance with the Official worldwide student protection policy, we
   * Do not track, profile or market to students and suspected students
   * For this, we must remove cookies associated with them and opt them out of
   * marketing in their cookies preferences so that we don't continue to track them
   * @method
   * @returns {Promise} Promise that resolves when all the marketing cookies are removed
   */
  // See https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=cclight&title=Turn+off+Marketing+and+Advertising+cookies+for+Edu+Users
  turnOffTrackingForEduUsers = () => {
    if (this.isEduUser()) {
      turnOffPersonalizedAdsConsent();
      return removePersonalizedAdsCookies();
    }
    return Promise.resolve();
  }

  /**
   * @description
   * Returns ClientID for a user
   * @method
   * @returns {String} - The IMS ClientID
   */
  getClientID = () => window.adobeid.client_id;

  /**
   * @description
   * Returns IMSlib isReady flag
   * Method is deprecated in IMSlib v2
   * @method
   * @returns {Boolean} - Flag indicating IMSlib is ready
   */
  isReady = () => !!window.adobe_dc_sdk.imsReady;

  /**
   * @description
   * Returns IMS locale for a user
   * @method
   * @returns {String} - The IMS locale
   */
  getLocale = () => window.adobeid.locale;
}

const auth2 = new Auth2API();
export { auth2, Auth2API };
