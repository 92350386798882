/*************************************************************************
 * ADOBE SYSTEMS INCORPORATED
 *  Copyright 2023 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying it.
 * If you have received this file from a source other than Adobe, then your
 * use, modification, or distribution of it requires the prior written
 * permission of Adobe.
 **************************************************************************/

import uuidv4 from 'uuid/v4';

export default class MethodOverrideManager {
  constructor(methodName, bootstrap) {
    this.bootstrap = bootstrap;
    this.methodName = methodName;
    this.overrideList = [];
  }

  async execute(...args) {
    const originalMethod = this.bootstrap[`_${this.methodName}`];

    // if override method is available
    if (this.overrideList.length) {
      // execute latest override method (last element in overrideList)
      const { method: overrideMethod } = this.overrideList[this.overrideList.length - 1];

      // passing original method for simple pre / post method override
      // passing bootstrap object for complex override handling
      return overrideMethod(originalMethod, this.bootstrap, ...args);
    }

    return originalMethod(...args);
  }

  remove(overrideId) {
    // look for matched index
    const index = this.overrideList.findIndex(({ id }) => id === overrideId);

    // if overrideId does not exist
    if (index < 0) {
      return false;
    }

    // remove override method from list
    this.overrideList.splice(index, 1);

    return true;
  }

  use(overrideMethod) {
    // generate unique id
    const overrideId = uuidv4();
    // push override into list
    this.overrideList.push({ id: overrideId, method: overrideMethod });

    return overrideId;
  }
}
