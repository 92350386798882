/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2020 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import UIActionHandlerServiceBase from "bundle/service/UIActionHandlerService";
import IFrameMessageTypes from "common/constants/IFrameMessageTypes";
import uuidV4 from "uuid/v4";

export default class UIActionHandlerService extends UIActionHandlerServiceBase {
    constructor() {
        super();
        this._uiActionMap = new Map();
    }

    initialize(origUIActionConfig, serviceFactory) {
        // validate custom UI Icons config and push to uiActionConfig array
        const validationService = serviceFactory.getService("ValidationService");
        const messagingService = serviceFactory.getService("MessagingService");
        if (validationService.validateUIActionConfig(origUIActionConfig)) {
            origUIActionConfig.forEach(config => {
                const filteredUIActionConfig = { ...config, id: uuidV4() };
                delete filteredUIActionConfig.onClick;
                this._uiActionConfig.push(filteredUIActionConfig);
                this._uiActionMap.set(filteredUIActionConfig.id, config.onClick);
            });
            this._handleUIActionEvent(messagingService);
        }
    }

    _handleUIActionEvent(messagingService) {
        const handleCustomIconEvent = typeData => {
            if (this._uiActionMap.has(typeData.id)) {
                this._uiActionMap.get(typeData.id)();
            }
        };
        messagingService.listenForType(
            IFrameMessageTypes.CUSTOM_ICON_CLICK,
            handleCustomIconEvent,
        );
    }
}
