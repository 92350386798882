/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
* Copyright 2021 Adobe
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import {
    ANALYTICS_OBJECT_INIT_TIMEOUT,
    ANALYTICS_OBJECT_PRESENCE_RECHECK_TIMEOUT,
} from "app/constants/TimeoutConstants";

export default class AnalyticsSenderService {
    constructor() {
        this._reportSuiteId = undefined; // For Adobe Analytics
        this._measurementId = undefined; // For Google Analytics
        this._analyticsObject = undefined;
    }

    initialize({ reportSuiteId, measurementId } = {}) {
        this._reportSuiteId = typeof reportSuiteId === "string" ? reportSuiteId : undefined;
        this._measurementId = typeof measurementId === "string" ? measurementId : undefined;
        this._initAt = new Date().getTime();
        this._checkForAPresence();
    }

    // Checks and initialize analytics object
    _checkForAPresence() {
        if (this.isEventForAdobeAnalytics && window.s_gi) {
            this._analyticsObject = window.s_gi(this._reportSuiteId);
        } else if (window.s) {
            this._analyticsObject = window.s;
        } else if (this.isEventForGoogleAnalytics && window.gtag) {
            this._analyticsObject = window.gtag;
        }

        const now = new Date().getTime();
        if (!this._analyticsObject && (now - this._initAt) < ANALYTICS_OBJECT_INIT_TIMEOUT) {
            setTimeout(() => {
                this._checkForAPresence();
            }, ANALYTICS_OBJECT_PRESENCE_RECHECK_TIMEOUT);
        }
    }

    // Checks whether event is for Adobe Analytics or not
    get isEventForAdobeAnalytics() {
        return !!this._reportSuiteId;
    }

    // Checks whether event is for Google Analytics or not
    get isEventForGoogleAnalytics() {
        return !!this._measurementId;
    }

    // provides data object
    get dataObjectForAA() {
        return this._analyticsObject.contextData;
    }

    // Checks for all conditions required to send event for analytics
    // with priority - AdobeAnalytics, GoogleAnalytics, default.
    get canSendEvent() {
        if (this.isEventForAdobeAnalytics || window.s) {
            return this._analyticsObject && typeof this._analyticsObject.t === "function";
        }
        if (this.isEventForGoogleAnalytics) {
            return this._analyticsObject && typeof this._analyticsObject === "function";
        }
        return false;
    }

    // Sends event data object to single Analytics Platform with priority - AdobeAnalytics, GoogleAnalytics.
    sendEvent({ dataObjectForGA } = {}) {
        if (this.isEventForAdobeAnalytics || window.s) {
            this._analyticsObject.t();
        } else if (this.isEventForGoogleAnalytics) {
            this._analyticsObject("event", dataObjectForGA.action, {
                ...dataObjectForGA.parameters,
                send_to: this._measurementId,
            });
        }
    }

    // Clears Analytics Object.
    clearData() {
        this._analyticsObject.clearVars();
    }

    // Used to get Query Params
    getQueryParamFunction() {
        return this._analyticsObject?.Util?.getQueryParam;
    }
}
