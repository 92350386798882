/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2019 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

const AnalyticsConstants = Object.freeze({
    BRAND_AREA: "brandArea",
    HUD_BRAND_AREA: "hudBrandArea",
    HELP_ICON: "helpIcon",
    FEEDBACK_ICON: "feedbackIcon",
    ABOUT_ICON: "aboutIcon",
    FILE_PREVIEW: "filePreview",
    LEGAL_NOTICES: "legalNotices",
    LEGAL_NOTICES_CLOSE: "legalNoticesClose",
    LEGAL_NOTICES_PRIVACY: "legalNoticesPrivacy",
    LEGAL_NOTICES_COOKIES: "legalNoticesCookies",
    LEGAL_NOTICES_CMP: "legalNoticesCMP",
    LEGAL_NOTICES_TERMS: "legalNoticesTerms",
    LEGAL_NOTICES_POWERED_BY: "legalNoticesPoweredBy",
    LEGAL_NOTICES_DO_NOT_SELL: "legalNoticesDoNotSell",
    LEGAL_NOTICES_THIRD_PARTY: "legalNoticesTermsThirdParty",
    SIDE_MENU_CALL_TO_ACTION: "sideMenuCallToAction",
    SIDE_TOOL_CALL_TO_ACTION: "sideToolCallToAction",
    SIGN_IN_ICON: "signInIcon",
    SIGN_OUT_ICON: "signOutIcon",
    BREADCRUMB_CLICK: "breadcrumbClick",
    CREATE_PDF_PICKER_OPEN: "createPdfPickerOpen",
    CREATE_PDF_PICKER_OPEN_SUCCESS: "createPdfPickerOpenSuccess",
    CREATE_PDF_PICKER_OPEN_NEW_SUCCESS: "createPdfPickerOpenNewSuccess",
    CREATE_PDF_PICKER_OPEN_CANCEL: "createPdfPickerOpenCancel",
    IMPORT_NOT_REGISTERD: "importNotRegisterd",
    IMPORT_START: "importStart",
    IMPORT_SUCCESS: "importSuccess",
    IMPORT_FAIL: "importFail",
    EXPORT_NOT_REGISTERD: "exportNotRegisterd",
    EXPORT_START: "exportStart",
    EXPORT_SUCCESS: "exportSuccess",
    EXPORT_FAIL: "exportFail",
    UPSELL_BLOCKED_VIEW: "upsellBlockedView",
    UPSELL_PLAN_VIEW: "upsellPlanView",
    UPSELL_VIEW_PLANS_CLICKED: "upsellViewPlansClicked",
    UPSELL_SIGN_IN_CLICKED: "upsellSignInClicked",
    HELP_URL: "helpUrl",
    UPSELL_CONFIRM_CLICKED: "upsellConfirmClicked",
    THUMBNAIL_VIEW: "thumbnailView",
    UPSELL_CONFIRM_SUCCESS: "upsellConfirmSuccess",
    UPSELL_CONFIRM_FAILURE: "upsellConfirmFailure",
    SCREEN_BLOCKED: "screenBlocked",
    TOAST_SHOWN: "toastShown",
    TUTORIAL_WELCOME: "tutorialWelcomeDialogShow",
    TUTORIAL_WELCOME_TAKE_A_TOUR: "tutorialWelcomeDialogTakeATourClicked",
    TUTORIAL_WELCOME_SKIP: "tutorialWelcomeDialogSkipClicked",
    TUTORIAL_WELCOME_CLOSE: "tutorialWelcomeDialogCloseClicked",
    TUTORIAL_WELCOME_ESCAPE: "tutorialWelcomeDialogEscapeClicked",
    TUTORIAL_FREE_TOOLS: "tutorialFreeToolsShow",
    TUTORIAL_FREE_TOOLS_SKIP: "tutorialFreeToolsSkipClicked",
    TUTORIAL_FREE_TOOLS_NEXT: "tutorialFreeToolsNextClicked",
    TUTORIAL_FREE_TOOLS_HIDE: "tutorialFreeToolsHide",
    TUTORIAL_FREE_TOOLS_DISMISSED: "tutorialFreeToolsDismissed",
    TUTORIAL_MORE_TOOLS: "tutorialMoreToolsShow",
    TUTORIAL_MORE_TOOLS_SKIP: "tutorialMoreToolsSkipClicked",
    TUTORIAL_MORE_TOOLS_NEXT: "tutorialMoreToolsNextClicked",
    TUTORIAL_MORE_TOOLS_DONE: "tutorialMoreToolsDoneClicked",
    TUTORIAL_MORE_TOOLS_HIDE: "tutorialMoreToolsHide",
    TUTORIAL_MORE_TOOLS_DISMISSED: "tutorialMoreToolsDismissed",
    NOTIFY_USER_DIALOG_SHOW: "notifyUserDialogShow",
    NOTIFY_USER_COPY_CONTENT: "notifyUserDialogCopyContent",
    NOTIFY_USER: "notifyUserDialogSendEmail",
    NOTIFY_USER_CLOSE: "notifyUserDialogClose",
    NOTIFY_USER_DONE: "notifyUserDialogDone",
    NOTIFY_USER_ESCAPE: "notifyUserDialogEscape",
    EDIT_HEADER_DOWNLOAD_BUTTON_CLICKED: "editHeaderDownloadButtonClicked",
    EDIT_HEADER_CANCEL_BUTTON_CLICKED: "editHeaderCancelButtonClicked",
    OPEN_IN_DESKTOP_APP_BUTTON_CLICKED: "openInDesktopAppButtonClicked",
    OPEN_IN_DESKTOP_BUTTON_CLICKED: "openInDesktopButtonClicked",
    OPEN_IN_DESKTOP_CANCEL_BUTTON_CLICKED: "openInDesktopCancelButtonClicked",
    OPEN_IN_DESKTOP_INSTALLHERE_LINK_CLICKED: "openInDesktopInstallHereLinkClicked",
});

export const AnalyticsActions = Object.freeze({
    LOAD_PDF: "Load PDF",
    PAGE_VIEW: "Page View",
    DOWNLOAD: "Download",
    PRINT: "Print",
    SEARCH: "Search",
    BOOKMARK_CLICK: "Bookmark Click",
    HYPERLINK_OPEN: "Hyperlink Open",
    TEXT_COPY: "Text Copy",
    CHANGE_ZOOM: "Change Zoom",
});

export default AnalyticsConstants;
