/*
*  Copyright 2017 Adobe Systems Incorporated. All rights reserved.
*  This file is licensed to you under the Apache License, Version 2.0 (the 'License");
*  you may not use this file except in compliance with the License. You may obtain a copy
*  of the License at http://www.apache.org/licenses/LICENSE-2.0
*
*  Unless required by applicable law or agreed to in writing, software distributed under
*  the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
*  OF ANY KIND, either express or implied. See the License for the specific language
*  governing permissions and limitations under the License.
**************************************************************************/

/**
 * @file
 * @description
 * This is the entry point to the various service providers using delayed loading.
 * Any providers registered in dc-discovery will automatically be available via this
 * mechanism.  These providers may be referenced as:
 *
 * import {providers} from "dc-core";
 * providers[<provider-name>]().then(
 *   providerInstance => {...}
 * );
 *
 * Providers must implement a ready() method that initializes the provider and
 * returns a promise that resolves to return an instance of the provider.
 * The intent is that providers are initialized on first-access.
 *
 * There are special-case providers automatically initialized here:
 * dcapi -- the provider that holds the DCAPI authentication and /discovery results
 * discovery -- the dc-discovery script used to locate and load dropins and providers
 * floodgate -- the service that provides access to feature flags
 *
 * If the discovery setup needs to be customized, the caller may pass an overrides parameter to
 * the first call to providers.discovery():
 * * @example
 * import {providers} from "dc-core";
 * providers().discovery(overrides)...
 * 'overrides' is the same JSON format found as the AdobeComponents section of dc-discovery package.json
 * This will allow you to override settings for  dropins/providers or to add completely new components.
 * In order to override the URL for the discovery service itself, specify:
 * {"discovery": {"url": "<provider-url>"}}
 */

/* eslint guard-for-in: 0, no-multi-assign: 0 */

import DcapiAPI from './DcapiAPI';
// eslint-disable-next-line import/no-cycle
import FloodgateProviderImpl from './FloodgateProviderImpl';
// eslint-disable-next-line
import { discovery } from './Discovery';
import Asset2API from './Asset2API';
import UserAPI from './UserAPI';
// eslint-disable-next-line import/no-cycle
import LimitsProvider from './LimitsProvider';
// eslint-disable-next-line import/no-cycle
import Limits2Provider from './Limits2Provider';
// eslint-disable-next-line import/no-cycle
import SophiaProvider from './SophiaProvider';
// eslint-disable-next-line import/no-cycle
import ThemeAPI from './ThemeAPI';
// eslint-disable-next-line import/no-cycle
import TargetAPIProvider from './TargetAPIProvider';

// build the list of providers from what we find in discovery

let _discoveryProviders;
if (!_discoveryProviders) {
  // Ensure this only happens once.
  _discoveryProviders = {};
}

/**
 * @description
 * Add a provider instance to be made available via the Providers API
 * @method
 * @param {string} name - the name of the provider
 *      This name will be used as the function reference for this provider.
 *      e.g. If name is "foo", then we can reference: providers.foo().then(...).
 * @param {object} instnaceOrFcn - the provider instance or function
 *      An instance must implement a ready() method that returns a Promise for what's provided.
 *      A function must directly return a Promise for what's provided.
 * @returns {Promise} - The promise that resolves to the provider object on success.
 *                      Else returns error on reject of promise.
 * @public
 */
function addProvider(name, objOrFcn) {
  if (!_discoveryProviders[name]) {
    _discoveryProviders[name] = typeof objOrFcn === 'function'
      ? objOrFcn
      : () => objOrFcn.ready();
  } else {
    throw new Error(`Provider ${name} already exists!`);
  }
}

// Early registration of auth, dcapi, and floodgate providers.
// They're not external, but we want to reference them via the provider interface.
addProvider('dcapi', DcapiAPI.getInstance);
// temporary until we move the rendition provider away from the facade
addProvider('dcapi-facade', () => DcapiAPI.getInstance().then(api => {
  const dcapi = api.getDcapi();
  return {
    getDcapi: () => Promise.resolve(dcapi),
    ready: async () => dcapi,
    getUuid: op => dcapi.getUuid(op),
  };
}));

addProvider('floodgate', FloodgateProviderImpl.getInstance);
// To add the DCAPI based providers
addProvider('asset2', Asset2API.getInstance);
addProvider('user', UserAPI.getInstance);
addProvider('limits', LimitsProvider.getInstance);
addProvider('limits2', Limits2Provider.getInstance);
addProvider('sophia', SophiaProvider.getInstance);
addProvider('theme', ThemeAPI.getInstance);
addProvider('target', TargetAPIProvider.getInstance);

/**
 * @description
 * _newLoader returns a function that will return the ready() function of the requested provider.
 * This is what allows us to use the provider name as a function to return a promise.
 * @method
 * @param {object} provider - The provider whose ready() method is requested.
 * @returns {function} - The function that returns the ready() method
 * @example
 * eg. provider.conversion().then(...)
 * This also allows for args to be stored via providers.conversion(args).then(...);
 * @private
 */
function _newLoader(provider) {
  let promise;
  return (...args) => {
    if (!promise) {
      // Use the Class.getInstance if available which handles ready() itself.
      // Otherwise create a new Class for the provider promise and call its ready().
      promise = _discoveryProviders.discovery().then(d => d.loadProviderClass(provider)
        .then(Class => (typeof Class.getInstance === 'function'
          ? Class.getInstance(...args)
          : new Class(...args).ready()),
        ));
    }
    return promise;
  };
}

let discoveryPromise;
_discoveryProviders.discovery = (overrides, pinnedVersions) => {
  if (!discoveryPromise) {
    // discovery.ready() returns a new promise object with each call.
    // make sure we call it only once.
    discoveryPromise = discovery.ready(overrides, pinnedVersions).then(() => {
      // initialize functions for all the providers found in discovery
      let provider;
      // eslint-disable-next-line
      for (provider in discovery.providers) {
        // Not to include providers already added
        // Don't include the built-in dc-core providers
        if (!_discoveryProviders[provider] && discovery.providers[provider].component !== 'dc-core') {
          _discoveryProviders[provider] = _newLoader(provider);
        }
      }
      return discovery;
    });
  }
  return discoveryPromise;
};

export { addProvider };
export const providers = _discoveryProviders;
